import styled from "styled-components";
import {useApp} from "../../contexts/App";
import {useNavigate} from "react-router-dom";
import NotifyImage from "../../assets/images/notify.png";
// @ts-ignore
import * as md5 from "md5";
import {truncate} from "../../services/app.service";

const Main = styled.div`
  padding: 0px 30px;
  height: 60px;
  background: #007E7A;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFFFFF;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
`;

const Account = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
  margin-left: -12px;
  height: 60px;
  cursor: pointer;

  &:hover {
    background: #006561;
  }

  &:hover > div:last-child {
    display: flex;
  }
`;
const AccountName = styled.div`
  line-height: 20px;
  text-align: right;
  margin-right: 9px;
  font-weight: 400;
  font-size: 14px;
  color: #F2EDD4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`;
const AccountAvt = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 12px;
`;
const Notify = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 17px;

`;


const UserIcon = () => {
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1728_21444)">
                <path
                    d="M6.3 7.2C8.28844 7.2 9.9 5.58844 9.9 3.6C9.9 1.61156 8.28844 0 6.3 0C4.31156 0 2.7 1.61156 2.7 3.6C2.7 5.58844 4.31156 7.2 6.3 7.2ZM8.82 8.1H8.35031C7.72594 8.38688 7.03125 8.55 6.3 8.55C5.56875 8.55 4.87687 8.38688 4.24969 8.1H3.78C1.69312 8.1 0 9.79312 0 11.88V13.05C0 13.7953 0.604688 14.4 1.35 14.4H9.08156C9.01406 14.2087 8.98594 14.0063 9.00844 13.8009L9.19969 12.0881L9.23344 11.7759L9.45563 11.5537L11.6297 9.37969C10.9406 8.60063 9.94219 8.1 8.82 8.1ZM10.0941 12.1866L9.90281 13.9022C9.87187 14.1891 10.1138 14.4309 10.3978 14.3972L12.1106 14.2059L15.9891 10.3275L13.9725 8.31094L10.0941 12.1866ZM17.8031 7.56281L16.7372 6.49687C16.4756 6.23531 16.0481 6.23531 15.7866 6.49687L14.7234 7.56L14.6081 7.67531L16.6275 9.69187L17.8031 8.51625C18.0647 8.25187 18.0647 7.82719 17.8031 7.56281Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1728_21444">
                    <rect width="18" height="14.4" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
const LogoutIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5ZM12.75 12L16.5 9L12.75 6V8.25H6.75V9.75H12.75V12Z"
                fill="white"/>
        </svg>
    );
};

const MenuPopupBackground = styled.div`
  display: none;
  position: absolute;
  padding-top: 76px;
  z-index: 9;
  top: -10px;
  right: 28px;

  &:after {
    content: " ";
    position: absolute;
    top: 66px;
    left: 80%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #035856 transparent;
  }
`;
const MenuPopup = styled.div`
  top: 0px;
  overflow: hidden;
  width: 230px;
  background: #005855;
  border-radius: 4px;
`;

const MenuPopupInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  & > img {
    width: 62px;
    height: 62px;
    background: #9BB5B4;
    border-radius: 100px;
    margin-bottom: 5px;
  }

  & > p {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;

const MenuPopupItem = styled.div`
  background-color: #006561;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  height: 35px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-left: 9px;
  }
`;


export const Topbar = (props: {
    title?: string,
}) => {
    const {user, logout} = useApp();
    const navigate = useNavigate();
    const gavatar = `https://www.gravatar.com/avatar/${user?.attributes?.email ? md5(user?.attributes?.email) : ""}?d=mp`;

    const onProfile = () => {
        navigate(`/user/admins/update/me`)
    }


    return (
        <Main>
            <Title>
                {/*{props?.title || ""}*/}
            </Title>
            <Right>
                {/*<Notify src={NotifyImage}/>*/}
                <Account>
                    <AccountName>Hello, {truncate(user?.full_name || 'No name', 10)}</AccountName>
                    <AccountAvt src={gavatar}/>
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6.5 6L12 1" stroke="#F2EDD4"/>
                    </svg>

                    <MenuPopupBackground>
                        <MenuPopup>
                            <MenuPopupInfo>
                                <img src={gavatar}/>
                                <p>{truncate(user?.full_name || 'No name', 10)}</p>
                                <span>{user?.email}</span>
                            </MenuPopupInfo>

                            <MenuPopupItem onClick={() => onProfile()}>
                                <UserIcon/>
                                <span>Profile</span>
                            </MenuPopupItem>
                            <MenuPopupItem onClick={logout}>
                                <LogoutIcon/>
                                <span>Logout</span>
                            </MenuPopupItem>
                        </MenuPopup>
                    </MenuPopupBackground>
                </Account>
            </Right>

        </Main>
    )
}