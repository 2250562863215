import React, { Suspense, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import { useApp } from "../contexts/App";
import { Layout } from "../components/Layout";
import Loading from "../components/Loading";
import NotificationConfig from "../pages/setting/notification/edit";

const Auth = React.lazy(() => import("../pages/auth"));
const Home = React.lazy(() => import("../pages/home"));

const AdminList = React.lazy(() => import("../pages/admin/list"));
const AdminEdit = React.lazy(() => import("../pages/admin/edit"));

const UserList = React.lazy(() => import("../pages/user/list"));
const UserEdit = React.lazy(() => import("../pages/user/edit"));

const AttributeEdit = React.lazy(() => import("../pages/attribute/edit"));
const AttributeList = React.lazy(() => import("../pages/attribute/list"));

const TrailEdit = React.lazy(() => import("../pages/trail/edit"));
const TrailList = React.lazy(() => import("../pages/trail/list"));

const MarathonTrailList = React.lazy(() => import("../pages/marathon-trail/list"));
const MarathonTrailEdit = React.lazy(() => import("../pages/marathon-trail/edit"));
const MarathonTrailCreate = React.lazy(() => import("../pages/marathon-trail/edit"));

const StayList = React.lazy(() => import("../pages/stay/list"));
const StayEdit = React.lazy(() => import("../pages/stay/edit"));
const StayCreate = React.lazy(() => import("../pages/stay/edit"));

const EatLocalList = React.lazy(() => import("../pages/eat-local/list"));
const EatLocalEdit = React.lazy(() => import("../pages/eat-local/edit"));
const EatLocalCreate = React.lazy(() => import("../pages/eat-local/edit"));

const TrailUpdateHistory = React.lazy(() => import("../pages/trail/history"))
const ReviewEdit = React.lazy(() => import("../pages/review/edit"));
const ReviewList = React.lazy(() => import("../pages/review/list"));

const PhotoEdit = React.lazy(() => import("../pages/photo/edit"));
const PhotoList = React.lazy(() => import("../pages/photo/list"));

const ActivityEdit = React.lazy(() => import("../pages/activity/edit"));
const ActivityList = React.lazy(() => import("../pages/activity/list"));

const SettingAdsEdit = React.lazy(() => import("../pages/setting/ads/edit"));
const AppVersionConfig = React.lazy(() => import("../pages/setting/version/edit"))
const AppSettingConfig = React.lazy(() => import("../pages/setting/setting/edit"))
const CrawlEdit = React.lazy(() => import("../pages/crawl/edit"));
const CrawlList = React.lazy(() => import("../pages/crawl/list"));

const ChallengeEdit = React.lazy(() => import("../pages/challenge/edit"));
const ChallengeList = React.lazy(() => import("../pages/challenge/list"));


let PREV_URL = window.location.href.replace(origin, "");

export const routers = [
    {
        path: "/",
        element: Home,
        meta: {
            title: "Home",
            breadcrumb: [{ path: null, breadcrumbName: "Home" }],
        },
    },
    {
        path: "/user/admins",
        element: AdminList,
        meta: {
            title: "Admins",
            breadcrumb: [{ path: null, breadcrumbName: "Admins" }],
        },
    },
    {
        path: "/user/admins/update/:id",
        element: AdminEdit,
        meta: {
            title: "Update Admin",
            breadcrumb: [
                { path: "/user/admins", breadcrumbName: "Admins" },
                { path: null, breadcrumbName: "Update admin" },
            ],
        },
    },
    {
        path: "/user/admins/create",
        element: AdminEdit,
        meta: {
            title: "Create Admin",
            breadcrumb: [
                { path: "/user/admins", breadcrumbName: "Admins" },
                { path: null, breadcrumbName: "Create Admin" },
            ],
        },
    },
    {
        path: "/user/customers",
        element: UserList,
        meta: {
            title: "Customers",
            breadcrumb: [{ path: null, breadcrumbName: "Customers" }],
        },
    },
    {
        path: "/user",
        element: () => <Navigate replace to="/user/customers" />,
        meta: {
            title: "Customers",
            breadcrumb: [{ path: null, breadcrumbName: "Customers" }],
        },
    },
    {
        path: "/user/customers/update/:id",
        element: UserEdit,
        meta: {
            title: "Update Customer",
            breadcrumb: [
                { path: "/user/customers", breadcrumbName: "Customers" },
                { path: null, breadcrumbName: "Update Customer" },
            ],
        },
    },
    {
        path: "/user/customers/create",
        element: UserEdit,
        meta: {
            title: "Add Customer",
            breadcrumb: [
                { path: "/user/customers", breadcrumbName: "Customers" },
                { path: null, breadcrumbName: "Add Customer" },
            ],
        },
    },
    {
        path: "/trail/attributes",
        element: AttributeList,
        meta: {
            title: "Attributes",
            breadcrumb: [{ path: null, breadcrumbName: "Attributes" }],
        },
    },
    {
        path: "/trail/attributes/update/:id",
        element: AttributeEdit,
        meta: {
            title: "Update Attribute",
            breadcrumb: [
                { path: "/trail/attributes", breadcrumbName: "Attributes" },
                { path: null, breadcrumbName: "Update Attribute" },
            ],
        },
    },
    {
        path: "/trail/attributes/create",
        element: AttributeEdit,
        meta: {
            title: "Add Attribute",
            breadcrumb: [
                { path: "/trail/attributes", breadcrumbName: "Attributes" },
                { path: null, breadcrumbName: "Add Attribute" },
            ],
        },
    },
    {
        path: "/trail/trails",
        element: TrailList,
        meta: {
            title: "Trails",
            breadcrumb: [{ path: null, breadcrumbName: "Trails" }],
        },
    },
    {
        path: "/trail",
        element: () => <Navigate replace to="/trail/trails" />,
        meta: {
            title: "Trails",
            breadcrumb: [{ path: null, breadcrumbName: "Trails" }],
        },
    },
    {
        path: "/trail/trails/update/:id",
        element: TrailEdit,
        meta: {
            title: "Update Trail",
            breadcrumb: [
                { path: "/trail/trails", breadcrumbName: "Trails" },
                { path: null, breadcrumbName: "Update Trail" },
            ],
        },
    },
    {
        path: "/trail/trails/history/:trailId/:historyId",
        element: TrailUpdateHistory,
        meta: {
            title: "Route old version",
            breadcrumb: [
                { path: "/trail/trails", breadcrumbName: "Trails" },
                { path: null, breadcrumbName: "Route old version" },
            ],
        },
    },
    {
        path: "/trail/trails/create",
        element: TrailEdit,
        meta: {
            title: "Add Trail",
            breadcrumb: [
                { path: "/trail/trails", breadcrumbName: "Trails" },
                { path: null, breadcrumbName: "Add Trail" },
            ],
        },
    },

    {
        path: "/trail/marathon-trail",
        element: MarathonTrailList,
        meta: {
            title: "Marathon Trails",
            breadcrumb: [
                { path: null, breadcrumbName: "Marathon Trails" },
            ],
        },
    },
    {
        path: "/trail/marathon-trail/create",
        element: MarathonTrailCreate,
        meta: {
            title: "Create Marathon Trail",
            breadcrumb: [
                { path: "/trail/marathon-trail", breadcrumbName: "Marathon Trails" },
                { path: null, breadcrumbName: "Create Marathon Trail" },
            ],
        },
    },
    {
        path: "/trail/marathon-trail/update/:id",
        element: MarathonTrailEdit,
        meta: {
            title: "Update Marathon Trail",
            breadcrumb: [
                { path: "/trail/marathon-trail", breadcrumbName: "Marathon Trail" },
                { path: null, breadcrumbName: "Update Marathon Trail" },
            ],
        },
    },

    {
        path: "/trail/stay",
        element: StayList,
        meta: {
            title: "Stay",
            breadcrumb: [
                { path: null, breadcrumbName: "Stay" },
            ],
        },
    },
    {
        path: "/trail/stay/create",
        element: StayCreate,
        meta: {
            title: "Create Stay Location",
            breadcrumb: [
                { path: "/trail/stay", breadcrumbName: "Stay" },
                { path: null, breadcrumbName: "Create Stay" },
            ],
        },
    },
    {
        path: "/trail/stay/update/:id",
        element: StayEdit,
        meta: {
            title: "Update Stay",
            breadcrumb: [
                { path: "/trail/stay", breadcrumbName: "Stay" },
                { path: null, breadcrumbName: "Update Stay" },
            ],
        },
    },

    {
        path: "/trail/eat-local",
        element: EatLocalList,
        meta: {
            title: "Eat local",
            breadcrumb: [
                { path: null, breadcrumbName: "Eat local" },
            ],
        },
    },
    {
        path: "/trail/eat-local/create",
        element: EatLocalCreate,
        meta: {
            title: "CREATE EAT LOCAL LOCATION",
            breadcrumb: [
                { path: "/trail/eat-local", breadcrumbName: "Eat Local" },
                { path: null, breadcrumbName: "Create Eat Local" },
            ],
        },
    },
    {
        path: "/trail/eat-local/update/:id",
        element: EatLocalEdit,
        meta: {
            title: "Update EAT LOCAL LOCATION",
            breadcrumb: [
                { path: "/trail/eat-local", breadcrumbName: "Eat Local" },
                { path: null, breadcrumbName: "Update Eat Local" },
            ],
        },
    },

    {
        path: "/trail/reviews",
        element: ReviewList,
        meta: {
            title: "Reviews",
            breadcrumb: [{ path: null, breadcrumbName: "Reviews" }],
        },
    },
    {
        path: "/trail/reviews/update/:id",
        element: ReviewEdit,
        meta: {
            title: "Update Review",
            breadcrumb: [
                { path: "/trail/reviews", breadcrumbName: "Reviews" },
                { path: null, breadcrumbName: "Update Review" },
            ],
        },
    },
    {
        path: "/trail/reviews/create",
        element: ReviewEdit,
        meta: {
            title: "Add Review",
            breadcrumb: [
                { path: "/trail/reviews", breadcrumbName: "Reviews" },
                { path: null, breadcrumbName: "Add Review" },
            ],
        },
    },

    {
        path: "/trail/photos",
        element: PhotoList,
        meta: {
            title: "Photos",
            breadcrumb: [{ path: null, breadcrumbName: "Photos" }],
        },
    },
    {
        path: "/trail/photos/update/:id",
        element: PhotoEdit,
        meta: {
            title: "Update Photo",
            breadcrumb: [
                { path: "/trail/photos", breadcrumbName: "Photos" },
                { path: null, breadcrumbName: "Update Photo" },
            ],
        },
    },
    {
        path: "/trail/photos/create",
        element: PhotoEdit,
        meta: {
            title: "Add Photos",
            breadcrumb: [
                { path: "/trail/photos", breadcrumbName: "Photos" },
                { path: null, breadcrumbName: "Add Photo" },
            ],
        },
    },

    {
        path: "/activities",
        element: ActivityList,
        meta: {
            title: "Activities",
            breadcrumb: [{ path: null, breadcrumbName: "Activities" }],
        },
    },
    {
        path: "/activities/update/:id",
        element: ActivityEdit,
        meta: {
            title: "Update Activity",
            breadcrumb: [
                { path: "/activities", breadcrumbName: "Activities" },
                { path: null, breadcrumbName: "Update Activity" },
            ],
        },
    },
    {
        path: "/activities/create",
        element: ActivityEdit,
        meta: {
            title: "Add Activity",
            breadcrumb: [
                { path: "/activities", breadcrumbName: "Activities" },
                { path: null, breadcrumbName: "Add Activity" },
            ],
        },
    },

    {
        path: "/trail/challenges",
        element: ChallengeList,
        meta: {
            title: "Challenges",
            breadcrumb: [{ path: null, breadcrumbName: "Challenges" }],
        },
    },
    {
        path: "/trail/challenges/details/:id",
        element: ChallengeEdit,
        meta: {
            title: "Challenge Details",
            breadcrumb: [
                { path: "/trail/challenges", breadcrumbName: "Challenges" },
                { path: null, breadcrumbName: "Challenge Details" },
            ],
        },
    },
    {
        path: "/setting",
        element: () => <Navigate replace to="/setting/ads" />,
        meta: {
            title: "Setting",
            breadcrumb: [{ path: null, breadcrumbName: "Setting" }],
        },
    },
    {
        path: "/setting/ads",
        element: SettingAdsEdit,
        meta: {
            title: "Ad config",
            breadcrumb: [
                { path: "/setting", breadcrumbName: "Setting" },
                { path: null, breadcrumbName: "AD" },
            ],
        },
    },
    {
        path: "/setting/app-version",
        element: AppVersionConfig,
        meta: {
            title: "App version config",
            breadcrumb: [
                { path: "/setting", breadcrumbName: "Setting" },
                { path: null, breadcrumbName: "App version" },
            ],
        },
    },
    {
        path: "/setting/app-setting",
        element: AppSettingConfig,
        meta: {
            title: "App setting config",
            breadcrumb: [
                { path: "/setting", breadcrumbName: "Setting" },
                { path: null, breadcrumbName: "App setting" },
            ],
        },
    },
    {
        path: "/setting/app-notification",
        element: NotificationConfig,
        meta: {
            title: "notification",
            breadcrumb: [
                { path: "/setting", breadcrumbName: "Setting" },
                { path: null, breadcrumbName: "Notification" },
            ],
        },
    },
    {
        path: "/crawl",
        element: CrawlList,
        meta: {
            title: "Crawl management",
            breadcrumb: [
                { path: null, breadcrumbName: "Crawl management" },
            ],
        },
    },
    {
        path: "/crawl/update/:id",
        element: CrawlEdit,
        meta: {
            title: "Update Destination",
            breadcrumb: [
                { path: "/crawl", breadcrumbName: "Crawl" },
                { path: null, breadcrumbName: "Update" },
            ],
        },
    }
];

function AppRouter() {
    const { user } = useApp();

    return (
        <Suspense fallback={null}>
            {!!user ? (
                <Router>
                    <Layout>
                        <Routes>
                            <Route
                                path="/auth/*"
                                element={
                                    <Navigate replace to="/trail/trails" />
                                }
                            />
                            <Route
                                path="/"
                                element={
                                    <Navigate replace to="/trail/trails" />
                                }
                            />
                            {routers.map((value, index) => {
                                const Element = value.element;
                                return (
                                    <Route
                                        key={index.toString()}
                                        path={value.path}
                                        element={
                                            <React.Suspense
                                                fallback={<Loading />}
                                            >
                                                <Element />
                                            </React.Suspense>
                                        }
                                    />
                                );
                            })}
                        </Routes>
                    </Layout>
                </Router>
            ) : (
                <Router>
                    <Routes>
                        <Route
                            path="/*"
                            element={<Navigate replace to="/auth/login" />}
                        />
                        <Route
                            path="/auth"
                            element={<Navigate replace to="/auth/login" />}
                        />
                        <Route
                            path="/auth/login"
                            element={
                                <React.Suspense fallback={<Loading />}>
                                    <Auth
                                        redirectUrl={
                                            PREV_URL.includes("")
                                                ? PREV_URL
                                                : null
                                        }
                                    />
                                </React.Suspense>
                            }
                        />
                    </Routes>
                </Router>
            )}
        </Suspense>
    );
}

export default AppRouter;
