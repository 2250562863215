import React, { useEffect, useState } from "react";
import { listAttribute } from "../../services/app.service";
import { SelectFormView, SelectListFormView, SelectMultipleFormView } from "./index.";

export const ATTRIBUTES = {
  activity_types: { id: "Activity types", name: "Activity types" },
  difficulty: { id: "Difficulty", name: "Level" },
  suitability: { id: "Suitability", name: "Suitability" },
  attractions: { id: "Attractions", name: "Attractions" },
  route_type: { id: "Route type", name: "Route type" },
  traffic: { id: "Traffic", name: "Vehicle" },
  conditions: { id: "Conditions", name: "Conditions" },
  marathon_type: { id: "Marathon type", name: "Marathon type" },
  parking: { id: "Parking", name: "Parking" },
  // services: { id: "Services", name: "Services" },
  service_eat_local: { id: "Service eat local", name: "Service Eatlocal" },
  service_stay : { id: "Service stay", name: "Service Stay" },
  take_away: { id: "Take away", name: "Take away" },
  door: { id: "Door", name: "Door" },
};

const AttributeContext = React.createContext<any>({});

export const AttributeProvider = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<Array<any>>([]);

  useEffect(() => {
    loadData().then();
  }, []);

  const loadData = async () => {
    const result = await listAttribute({});
    if (result.status === 200) {
      setRows(
        (result?.data?.data?.items || []).sort((a: any, b: any) => {
          const indexA = a?.index || 0;
          const indexB = b?.index || 0;
          return indexA > indexB ? 1 : indexA < indexB ? -1 : 0;
        })
      );
    }
  };

  const getValues = (category: any) => {
    return rows
      .filter(value => value.group.toString().toLowerCase() === category.toString().toLowerCase())
      .map(value => {
        return {
          value: value.id,
          label: value.name,
        };
      });
  };

  return (
    <AttributeContext.Provider
      value={{
        loading,
        setLoading,
        getValues,
      }}>
      {children}
    </AttributeContext.Provider>
  );
};

const useAttribute = () => React.useContext(AttributeContext);

export const FormAttributeSelectView = (props: any) => {
  const hook = useAttribute();
  const options = hook.getValues(props.category.id);

  return props.mode == "multiple" ? (
    <SelectMultipleFormView
      options={options}
      loading={hook.loading}
      {...props}
      onChange={value => {
        props.onChange(value);
      }}
    />
  ) : (
    <SelectFormView
      options={options}
      loading={hook.loading}
      {...props}
      onChange={value => {
        props.onChange(value);
      }}
    />
  );
};

export const FormAttributeSelectListView = (props: any) => {
  const hook = useAttribute();
  const options = hook.getValues(props.category.id);

  return (
    <SelectListFormView
      options={options}
      loading={hook.loading}
      {...props}
      onChange={(value: any) => {
        props.onChange(value);
      }}
    />
  );
};

export const FormCategorySelectListView = (props: any) => {
  const hook = useAttribute();

  return (
    <SelectFormView
      options={props?.options}
      loading={hook.loading}
      {...props}
      onChange={value => {
        props.onChange(value);
      }}
    />
  );
};
