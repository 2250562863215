import styled from 'styled-components'
import {
  Button,
  Input,
  Checkbox,
  Select,
  Table,
  Tabs,
  Form,
  InputNumber,
  Rate,
  Spin,
  Modal,
  DatePicker,
  TimePicker,
} from 'antd'
import { Editor } from '@tinymce/tinymce-react'
import { useEffect, useRef, useState } from 'react'
import { dateFormat } from '../../services/app.service'
import { debounce } from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/lib/date-picker'
import { SelectView } from '../Controls'

export const InputNameViewText = styled(Input)`
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
`
export const InputNameView: any = (props: any) => {
  return (
    <InputNameViewText
      onBlur={(event: any) => {
        const text = event?.target?.value
        event.target.value = text?.trim() || ''
        props?.onChange(event)
      }}
      {...(props || {})}
    />
  )
}

export const TabEditView = styled(Tabs)`
  margin-top: -24px;

  & .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000;
  }

  & .ant-tabs-ink-bar {
    background: #006561;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #006561;
  }
`

export const FormItem = styled(Form.Item)`
  & .ant-form-item-row {
    display: block;
  }

  & .ant-form-item-label > label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }

  & .ant-form-item-label {
    position: relative;
  }

  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    position: absolute;
    right: -4px;
  }
`
export const InputFormViewText = styled(Input)`
  height: 40px !important;
  //background: #FFFFFF !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  width: 100%;
`

export const InputFormView2: any = (props: any) => {
  const [disabled, setDisabled] = useState<any>(false)
  if (disabled === false) {
    let getDisable = setInterval(() => {
      if (disabled === false) {
        if (props?.disabled) {
          setDisabled(props?.disabled)
        } else {
          console.log('.......')
          let disabled =
            moment(props?.valueInput?.notify_time).isBefore(moment()) &&
            !!props?.valueInput?.id
          setDisabled(disabled)
          if(disabled === true) {
            clearInterval(getDisable);
          }
        }
      }
    }, 1000)
  }

  return (
    <InputFormViewText
      disabled={disabled}
      onBlur={(event: any) => {
        const text = event?.target?.value
        event.target.value = text?.trim() || ''
        props?.onChange(event)
      }}
      {...(props || {})}
    />
  )
}

export const InputFormView: any = (props: any) => {
  return (
    <InputFormViewText
      onBlur={(event: any) => {
        const text = event?.target?.value
        event.target.value = text?.trim() || ''
        props?.onChange(event)
      }}
      {...(props || {})}
    />
  )
}

export const InputNumberFormView = styled(InputNumber)`
  height: 40px !important;
  //background: #FFFFFF !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  width: 100%;

  & .ant-input-number-input {
    height: 38px;
  }
`

export const TextAreaFormView = styled(Input.TextArea)`
  //background: #FFFFFF !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  width: 100%;
`
export const FormItemLabel = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
  height: 32px;
`

export const InputPasswordFormView = styled(Input.Password)`
  height: 40px !important;
  //background: #FFFFFF !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  width: 100%;
`

export const SelectFormView = styled(Select)`
  //max-width: 320px;

  & .ant-select-selector {
    height: 40px !important;
    //background: #FFFFFF !important;
    border: 1px solid #cccccc !important;
    border-radius: 5px !important;
    width: 100%;
  }

  & .ant-select-selection-search-input {
    height: 38px !important;
    line-height: 38px;
  }

  & .ant-select-selection-item {
    line-height: 40px !important;
  }

  &.big.ant-select-single
    .ant-select-selector
    .ant-select-multiple
    .ant-select-selection-placeholder {
    line-height: 38px;
  }
`

export const SelectFormView2 = styled(Select)`
  & .ant-select-selector {
    minheight: 40px !important;
    height: auto !important;
    border: 1px solid #cccccc !important;
    border-radius: 5px !important;
    width: 100%;
  }

  & .ant-select-selection-search-input {
    minheight: 38px !important;
    line-height: 38px;
    height: auto !important;
  }

  &.big.ant-select-single
    .ant-select-selector
    .ant-select-multiple
    .ant-select-selection-placeholder {
    line-height: 38px;
  }
`

export const SelectFormView2FormView = ({
  onChange,
  disabled,
  onBlur,
  valueInput,
  ...props
}: any) => {
  const currentRef = useRef<any>()
  const [disabledData, setDisabled] = useState<any>(false)

  if (disabledData === false) {
    let getDisable = setInterval(() => {
      if (disabledData === false) {
        if (disabled) {
          setDisabled(disabled)
        } else {
          let disabled =
            moment(valueInput?.notify_time).isBefore(moment()) && !!valueInput?.id
          setDisabled(disabled)
          if(disabled === true) {
            clearInterval(getDisable);
          }
        }
      }
    }, 1000)
  }

  const selectProps = {
    value: props?.value,
    placeholder: props?.placeholder,
    style: props?.style,
  }
  return (
    <SelectFormView2
      {...selectProps}
      {...props}
      id={props?.id}
      mode='multiple'
      ref={currentRef}
      disabled={disabledData}
      onBlur={onBlur}
      onChange={(values: any) => {
        currentRef && currentRef.current.focus()
        onChange(values)
      }}
    />
  )
}

export const SelectMultipleFormView = styled(Select)`
  //max-width: 320px;

  & .ant-select-selector {
    //background: #FFFFFF !important;
    border: 1px solid #cccccc !important;
    border-radius: 5px !important;
    width: 100%;
  }

  // & .ant-select-selection-search-input {
  //   height: 38px !important;
  //   line-height: 38px;
  // }

  // &.big.ant-select-single  .ant-select-multiple .ant-select-selection-placeholder {
  //   line-height: 38px;
  // }
`

export const RateFormView = styled(Rate)`
  & .ant-rate-star > div {
    font-size: 18px;
  }
`

export const FormButtonView = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff !important;
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  background: #006561 !important;
  border-color: #006561 !important;
  border-radius: 30px;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  ${(props: { outline?: any }) =>
    props?.outline
      ? `
    border: 1px solid #8A786A !important;
    background-color: #fff !important;
    color: #8A786A !important;
  `
      : ``}

  &.cancel {
    background: #d66a3e !important;
    border-color: #d66a3e !important;
  }

  &.mr {
    background: #527c56 !important;
    border-color: #527c56 !important;
  }
`

export const FormButtonCancelView = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff !important;
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  background: #006561 !important;
  border-color: #006561 !important;
  border-radius: 30px;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  ${(props: { outline?: any }) =>
    props?.outline
      ? `
    border: 1px solid #006561 !important;
    background-color: #fff !important;
    color: #006561 !important;
  `
      : ``}

  &.cancel {
    background: #d66a3e !important;
    border-color: #d66a3e !important;
  }

  &.mr {
    background: #527c56 !important;
    border-color: #527c56 !important;
  }
`

export const FormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button {
    margin-left: 12px;
  }
`
const EditorContainer = styled.div``

export const EditorFormView = (props: any) => {
  return (
    <EditorContainer>
      <Editor
        disabled={props?.disabled}
        value={props.value}
        onEditorChange={(value, editor) => {
          props.onChange(value)
        }}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
      />
    </EditorContainer>
  )
}

const SelectListContainer = styled.div`
  height: 150px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  overflow-y: auto;
  padding: 0px 12px;

  .ant-checkbox-group {
    width: 100%;
  }

  .ant-checkbox-group-item {
    display: flex;
    margin: 9px 0px;
  }

  .ant-checkbox + span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }

  .ant-checkbox-inner {
    border: 1px solid #cccccc;
    border-radius: 2px;
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #006561;
    border-color: #006561;
  }

  & .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
  }
`
export const SelectListFormView = (props: any) => {
  return (
    <SelectListContainer>
      <Checkbox.Group
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
      />
    </SelectListContainer>
  )
}

export const SelectFormServiceView = (props: any) => {
  const [rows, setRows] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [query, setQuery] = useState({})
  const [keyword, setKeyword] = useState('')
  const service = props?.service
  const serviceInit = props?.serviceInit
  const filterItems = props?.filterItems
  const initQuery = props?.initQuery
  const isReset = props?.isReset
  const selectProps = {
    value: props?.value,
    onChange: props?.onChange,
    placeholder: props?.placeholder,
    style: props?.style,
  }

  useEffect(() => {
    loadInit([props?.value]).then()
  }, [])

  const loadInit = async (ids: Array<string>) => {
    ids = ids.filter((item) => !!item)
    if (serviceInit && ids?.length) {
      const result = await serviceInit(ids)
      if (result.status === 200 && result?.data?.data?.items?.length >= 0) {
        setRows([...rows, ...result?.data?.data?.items])
      } else {
        if (result?.data?.data?.length >= 0) {
          setRows(result?.data?.data)
          setTotal(result?.data?.data?.length || 0)
        }
      }
    }
  }

  useEffect(() => {
    loadData().then()
  }, [page, limit, query, initQuery])
  const loadData = async () => {
    setLoading(true)
    const result = await service(
      props?.paging
        ? {
          page,
          limit,
          status: 'Active',
          sortKey: 'createdAt',
          sortBy: 'desc',
          ...query,
          ...(initQuery || {})
        }
        : { status: 'Active' }
    )
    if (result.status === 200 && result?.data?.data?.items?.length) {
      const newItems = result?.data?.data?.items || []
      let items = [...rows]
      if (isReset) {
        items = [...newItems]
      } else {
        for (let i = 0; i < newItems.length; i++) {
          if (!items.find((x) => x.id === newItems[i].id)) {
            items.push(newItems[i])
          }
        }
      }
      setRows(items)
      setTotal(result.data?.data?.page_info?.total || 0)
    }
    setLoading(false)
  }

  return (
    <SelectFormView
      {...selectProps}
      notFoundContent={loading ? <Spin size='small' /> : null}
      showSearch={!props.disableSearch}
      allowClear
      loading={loading}
      disabled={props.disabled}
      onPopupScroll={(event: any) => {
        var target = event.target
        if (
          !loading &&
          target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
          setPage(page + 1)
        }
      }}
      filterOption={(input, option) =>
        (option?.label || '').toLowerCase().includes(input.toLowerCase())
      }
      onSearch={debounce((value) => {
        setQuery({ ...query, keyword: value })
        setPage(1)
      }, 500)}
      options={rows.map((row: any) => {
        return {
          label: row[props.label],
          value: row.id,
          key: row.id,
        }
      })}
    />
  )
}


export const SelectFormViewOption = (props: any) => {
  const [rows, setRows] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState({})
  const serviceOption = props?.serviceOption
  const serviceInit = props?.serviceInit
  const selectProps = {
    value: props?.value,
    onChange: props?.onChange,
    placeholder: props?.placeholder,
    style: props?.style,
  }

  useEffect(() => {
    setRows([...serviceOption])
  }, [serviceOption])

  return (
    <SelectFormView
      {...selectProps}
      notFoundContent={loading ? <Spin size='small' /> : null}
      showSearch={!props.disableSearch}
      allowClear
      loading={loading}
      disabled={props.disabled}
      onPopupScroll={(event: any) => {
        var target = event.target
        if (
          !loading &&
          target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
          setPage(page + 1)
        }
      }}
      filterOption={(input, option) =>
        (option?.label || '').toLowerCase().includes(input.toLowerCase())
      }
      onSearch={debounce((value) => {
        setQuery({ ...query, keyword: value })
        setPage(1)
      }, 500)}
      options={rows.map((row: any) => {
        return {
          label: row.label,
          value: row.value,
          key: row.value,
        }
      })}
    />
  )
}


export const InputDateFormView = (props: any) => {
  return (
    <InputFormView
      {...props}
      value={
        props?.format ? props?.format(props.value) : dateFormat(props.value)
      }
    />
  )
}

export const TimePickerFormView = styled(TimePicker)`
  height: 40px !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  width: 100%;
`

export const DatePickerFormView = styled(DatePicker)`
  height: 40px !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  width: 100%;
`

export const InputDateTimeFormView = (props: any) => {
  const [disabledData, setDisabled] = useState<any>(false)

  if (disabledData === false) {
    let getDisable = setInterval(() => {
      if (disabledData === false) {
        if (props?.disabled) {
          setDisabled(props?.disabled)
        } else {
          let disabled =
            moment(props?.valueInput?.notify_time).isBefore(moment()) &&
            !!props?.valueInput?.id
          setDisabled(disabled)
          if(disabled === true) {
            clearInterval(getDisable);
          }
        }
      }
    }, 1000)
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    let halfAnHourAgo = moment().toDate().getTime()
    let currentDate = moment(current).toDate().getTime()
    return current && moment(currentDate).isBefore(halfAnHourAgo, 'date')
  }
  const range = (start: number, end: number) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const disabledDateTime = (current: any) => {
    let checkDay = moment(current).day()
    return {
      disabledHours: () =>
        checkDay > moment().add(30, 'm').day()
          ? []
          : range(0, moment().add(30, 'm').hours()),
      disabledMinutes: () =>
        checkDay > moment().day() ||
          moment(current).hours() > moment().add(30, 'm').hours()
          ? []
          : range(0, moment().add(30, 'm').minute()),
      disabledSeconds: () => [],
    }
  }

  let showTime: any = {
    defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
  }

  return (
    <DatePickerFormView
      {...props}
      disabled={disabledData}
      value={props.value ? moment(props.value) : ''}
      format='DD/MM/YYYY HH:mm'
      disabledDate={disabledDate}
      disabledTime={disabledDateTime}
      showTime={showTime}
      showNow={false}
    />
  )
}

export const IconArrow = styled.img`
  margin: 0 auto;
  display: block;
  width: 25px;
  margin-top: 5px;
`

export const InputFormNumbe = styled(InputNumber)`
  height: 40px !important;
  //background: #FFFFFF !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  width: 100%;

  & .ant-input-number-input {
    height: 38px;
  }
`

export const InputFormViewNumber: any = (props: any) => {
  return (
    <InputFormNumbe
      onBlur={(event: any) => {
        const text = event?.target?.value
        event.target.value = text?.trim() || ''
        props?.onChange(event)
      }}
      {...(props || {})}
    />
  )
}

export const ModalView = styled(Modal)`
  width: 334.56px;
  & .ant-modal-close-x {
    display: none;
  }
  & .ant-modal-content {
    border-radius: 10px;
  }
  & .ant-modal-body {
    background: #f2edd4;
    border: 2px solid #ffffff;
    box-shadow: 0px 4px 20px 0px #00000040;
    border-radius: 10px;
  }
`

export const FormActionsModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    margin-left: 12px;
  }
`

export const FormTitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FormTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #2d2d2d;
`
export const FormModal = styled(Form)`
  & .ant-form-item {
    margin-bottom: 8px;
  }
`
