import styled from "styled-components";
import { Popconfirm, Rate, Table, Tooltip } from "antd";
import { useState } from "react";

const Status = styled.span`
  font-weight: 500;
  font-size: 14px;
  ${(props: any) => {
    if (props.children.toString().toLowerCase() === 'active' || props.children.toString().toLowerCase() === 'actived') {
      return 'color: #527C56;'
    }
    if (props.children.toString().toLowerCase() === 'inactive') {
      return 'color: #AAAAAA;'
    }
    if (props.children.toString().toLowerCase() === 'rejected' || props.children.toString().toLowerCase() === 'removed') {
      return 'color: #D66A3E;'
    }
    if (props.children.toString().toLowerCase() === 'new' || props.children.toString().toLowerCase() === 'invited') {
      return 'color: #DFA533;'
    }
    if (props.children.toString().toLowerCase() === 'accepted' || props.children.toString().toLowerCase() === 'upcoming') {
      return 'color: #317BB1;'
    }
    if (props.children.toString().toLowerCase() === 'migrated' || props.children.toString().toLowerCase() === 'accepted') {
      return 'color: #527C56;'
    }
    if (props.children.toString().toLowerCase() === 'canceled' || props.children.toString().toLowerCase() === 'completed'
      || props.children.toString().toLowerCase() === 'declined'
    ) {
      return 'color: #D66A3E;'
    }
    return 'color: #000;'
  }}
`
export const StatusView = (props: { children?: any, value?: string }) => {
  return (
    <Status>{props?.value || ""}</Status>
  );
}

const Actions = styled.div`
  display: flex;
  align-items: center;
`;
const Action = styled.div`
  cursor: pointer;
  margin: 0 3px;
`;
const ActionIcon = styled.div``;
const ActionConfirm = styled(Popconfirm)``;
export const ActionsView = (props: {
  onEdit?: any,
  onRemove?: any,
  onNext?: any,
  removeTitle?: string
  onReject?: any
  onAccept?: any
  style?: any
}) => {
  return (
    <Actions style={props.style}>
      {props?.onEdit ? (
        <Action onClick={props.onEdit}>
          <ActionIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="#6D9ECC" />
              <path
                d="M6.66108 18C6.56972 17.9998 6.4794 17.9807 6.39586 17.9437C6.31231 17.9067 6.23737 17.8528 6.17579 17.7853C6.11311 17.7183 6.06527 17.6389 6.03543 17.5522C6.00558 17.4655 5.9944 17.3734 6.00262 17.2821L6.16394 15.5077L13.6164 8.05581L15.9454 10.3848L8.4949 17.836L6.721 17.9974C6.70108 17.9992 6.68108 18.0001 6.66108 18ZM16.4103 9.91911L14.082 7.59015L15.4786 6.19317C15.5397 6.13193 15.6123 6.08335 15.6923 6.05021C15.7722 6.01706 15.8579 6 15.9444 6C16.031 6 16.1166 6.01706 16.1966 6.05021C16.2765 6.08335 16.3491 6.13193 16.4103 6.19317L17.8069 7.59015C17.8681 7.65132 17.9167 7.72396 17.9498 7.80392C17.9829 7.88388 18 7.96959 18 8.05614C18 8.1427 17.9829 8.2284 17.9498 8.30836C17.9167 8.38832 17.8681 8.46096 17.8069 8.52213L16.4109 9.91845L16.4103 9.91911Z"
                fill="white" />
            </svg>
          </ActionIcon>
        </Action>
      ) : null}
      {props?.onRemove ? (
        <ActionConfirm
          placement={'topRight'}
          title={props.removeTitle || "Confirm remove?"}
          onConfirm={props.onRemove}
        >
          <Action>
            <ActionIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#D66A3E" />
                <path
                  d="M7.2 9.6H16.8V17.4C16.8 17.5591 16.7368 17.7117 16.6243 17.8243C16.5117 17.9368 16.3591 18 16.2 18H7.8C7.64087 18 7.48826 17.9368 7.37574 17.8243C7.26321 17.7117 7.2 17.5591 7.2 17.4V9.6ZM9 7.8V6.6C9 6.44087 9.06321 6.28826 9.17574 6.17574C9.28826 6.06321 9.44087 6 9.6 6H14.4C14.5591 6 14.7117 6.06321 14.8243 6.17574C14.9368 6.28826 15 6.44087 15 6.6V7.8H18V9H6V7.8H9ZM10.2 7.2V7.8H13.8V7.2H10.2ZM10.2 12V15.6H11.4V12H10.2ZM12.6 12V15.6H13.8V12H12.6Z"
                  fill="white" />
              </svg>
            </ActionIcon>
          </Action>
        </ActionConfirm>
      ) : null}

      {props?.onReject ? (
        <Tooltip title={"Cancel Acceptance"}>
          <Action onClick={props.onReject}>
            <ActionIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#D66A3E" />
                <path
                  d="M15.885 6L12 9.885L8.115 6L6 8.115L9.885 12L6 15.885L8.115 18L12 14.115L15.885 18L18 15.885L14.115 12L18 8.115L15.885 6Z"
                  fill="white" />
              </svg>
            </ActionIcon>
          </Action>
        </Tooltip>
      ) : null}
      {props?.onAccept ? (
        <Action onClick={props.onAccept}>
          <ActionIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="#317BB1" />
              <path d="M16.59 7L10 13.59L7.41 11.01L6 12.42L10 16.42L18 8.42L16.59 7Z" fill="white" />
            </svg>
          </ActionIcon>
        </Action>
      ) : null}
      {props?.onNext ? (
        <Action onClick={props.onNext}>
          <ActionIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="12" fill="#527C56" />
              <path d="M10.0002 16.3999L14.2002 12.1999L10.0002 7.9999" stroke="white" strokeWidth="1.5"
                strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </ActionIcon>
        </Action>
      ) : null}
    </Actions>
  )
}

const Line2 = styled.div``
const Line2Rate = styled(Rate)`
  & .ant-rate-star > div {
    font-size: 14px;
  }
`
const Line2Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Line2Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;


  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
export const ClickAble = styled.div`
  //cursor: pointer;
`
export const ClickColAble = styled.div`
  cursor: pointer;
`
const Line2Values = styled.div`
  display: flex
`

export const Line2View = (props: { name?: string, value?: string, color?: string, rate?: number, style?: any, text?: string, name2?: string | null }) => {
  return (
    <Line2 style={props.style || {}}>
      {props?.name ? (
        <Line2Values>
          <Line2Name style={props.color ? { color: props.color } : undefined}>{props?.name || ""}{props?.name2 ? <>&nbsp;-&nbsp;</> : ''}</Line2Name>
          {props?.name2 ? (
            <Line2Name style={{ color: '#D66A3E' }}>{props?.name2 || ""}</Line2Name>
          ) : null}
        </Line2Values>
      ) : null}
      {props?.text ? (
        <Line2Value style={props.color ? { color: props.color } : undefined}>{props?.text || ""}</Line2Value>
      ) : null}
      {props?.rate ? (
        <Line2Rate allowHalf disabled defaultValue={props?.rate || 0} />
      ) : null}
      <Line2Value style={props.color ? { color: props.color } : undefined}>{props?.value || ""}</Line2Value>
    </Line2>
  );
}

export const LineLinkView = (props: { name?: string, value?: string, color?: string, rate?: number, style?: any, text?: string }) => {
  return (
    <Line2 style={props.style || {}}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '5px',
        }}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.6666 8.60016V11.4002C10.6666 13.7335 9.73325 14.6668 7.39992 14.6668H4.59992C2.26659 14.6668 1.33325 13.7335 1.33325 11.4002V8.60016C1.33325 6.26683 2.26659 5.3335 4.59992 5.3335H7.39992C9.73325 5.3335 10.6666 6.26683 10.6666 8.60016Z"
            fill={props.color || "#515151"}
          />
          <path
            d="M11.4001 1.3335H8.60009C6.67939 1.3335 5.71176 1.97043 5.42932 3.49277C5.32773 4.04033 5.79508 4.50016 6.35199 4.50016H7.40009C10.2001 4.50016 11.5001 5.80016 11.5001 8.60016V9.64826C11.5001 10.2052 11.9599 10.6725 12.5075 10.5709C14.0298 10.2885 14.6668 9.32086 14.6668 7.40016V4.60016C14.6668 2.26683 13.7334 1.3335 11.4001 1.3335Z"
            fill={props.color || "#515151"}
          />
        </svg>
      </div>
      <Line2Value style={{ color: props.color, textDecoration: 'underline' }}>{props?.value || ""}</Line2Value>
    </Line2>
  );
}
export const TableView = styled(Table)`
  & .ant-table-thead > tr > th {

    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
    color: #523B31;
    background: #F9F6E9;
    height: 40px;
    padding: 0 5px;
  }

  & .ant-table-tbody > tr > td {
    padding: 10px 5px;
    max-width: 200px;
  }
`;

export const getTableId = (id: string) => {
  return "*****" + (id || '').toString().split('').reverse().join('').substring(0, 12).split('').reverse().join('');
}

export const TableThumbView = styled.img`
  width: 90px;
  height: 60px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  object-fit: cover;
`;
export const TableThumbNullView = styled((props: any) => {
  return (
    <svg {...props} width="90" height="60" viewBox="0 0 90 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="90" height="60" rx="3" fill="#9BB5B4" />
      <path opacity="0.2"
        d="M57.9364 20.3547C57.9364 20.0718 57.7774 19.789 57.5548 19.6005L55.3287 17.6521L47.8553 11.587L46.1698 10.2357C45.7564 9.92144 45.184 9.92144 44.8024 10.2357L43.1169 11.587L35.6436 17.5892L33.3857 19.4433C33.1312 19.6633 32.9722 19.9461 33.004 20.2918V20.9832V22.2716V28.8081V33.8361V33.8676L35.6436 35.9731L43.1169 41.9753L43.7847 42.5095V50.2716V50.303V50.3345C43.7847 50.6487 43.8801 50.9315 44.0392 51.1829C44.3254 51.6858 44.8978 52 45.502 52C46.4243 52 47.1557 51.2772 47.2193 50.3973C47.2193 50.3659 47.2193 50.1459 47.2193 50.1145V42.5095L47.8871 41.9753L55.3605 35.9731L58 33.8676V33.8361V28.8081V22.2716V21.1403L57.9364 20.3547ZM55.0743 32.1706C55.0743 32.5163 54.9153 32.8305 54.6608 33.0191L48.9684 37.3244C48.2369 37.8586 47.2193 37.3558 47.2193 36.4759V33.679C47.2193 33.4276 47.3147 33.2076 47.4419 33.0191L51.6397 27.6768C51.9895 27.2054 51.9259 26.5455 51.4489 26.1684L50.7175 25.6027C50.2404 25.2256 49.6998 25.3199 49.2864 25.7598C48.5232 26.514 47.2193 28.8395 47.2193 27.8025V19.4119C47.2193 18.8148 46.7423 18.3434 46.1381 18.3434H44.8978C44.2936 18.3434 43.8165 18.8148 43.8165 19.4119V27.8025C43.8165 28.8395 42.5127 26.6397 41.7812 25.8541C41.3678 25.4141 40.7954 25.1942 40.3184 25.6027L39.5869 26.1684C39.1099 26.5455 39.0463 27.2054 39.4279 27.6768L43.6257 33.0191C43.7847 33.2076 43.8483 33.4276 43.8483 33.679V36.4759C43.8483 37.3872 42.7989 37.89 42.0993 37.3244L35.9934 32.7048V33.1762V21.3288C35.9934 20.9832 36.1524 20.6689 36.4068 20.4804L44.8978 13.9753C45.2794 13.6611 45.8518 13.6611 46.2335 13.9753L54.7245 20.4804C54.9789 20.7003 55.1379 21.0146 55.1379 21.3288L55.0743 32.1706Z"
        fill="white" />
    </svg>
  )
})`
`;

export const TableTagsView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const TableTagActiveView = styled.div`
  height: 24px;
  background: rgba(45, 45, 45, 0.5);
  border-radius: 100px;
  padding: 5px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  align-items: center;
  display: inline-flex;
  margin-bottom: 0px;
  //cursor: pointer;

  & svg {
    margin-right: 4px;
  }

  &.challenge {
    background: #D66A3E;
  }

  &.easy {
    background: #3E8545;
  }

  &.moderate {
    background: #2976AE;
  }
`;
export const TableTagView = styled.div`
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #D1D1D1;
  border-radius: 100px;
  padding: 5px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #006561;
  margin-right: 8px;
  white-space: nowrap;
  //cursor: pointer;
  margin-top: 6px;
`;

export const TableStarView = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  display: flex;
  align-items: center;

  color: #000000;

  & > i {
    color: #FFD000;
    margin-right: 3px;
  }
`

export const ActionsClose = (props: {
  onClick?: any,
}) => {
  return (
    <Action>
      <ActionIcon onClick={props.onClick}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.6" cx="10.5" cy="10.5" r="10.5" fill="#006561" />
          <path d="M11.4291 10.5027L14.251 7.68737C14.3746 7.5638 14.444 7.3962 14.444 7.22144C14.444 7.04667 14.3746 6.87907 14.251 6.7555C14.1274 6.63192 13.9598 6.5625 13.7851 6.5625C13.6103 6.5625 13.4427 6.63192 13.3191 6.7555L10.5038 9.57737L7.68849 6.7555C7.56492 6.63192 7.39732 6.5625 7.22256 6.5625C7.04779 6.5625 6.88019 6.63192 6.75662 6.7555C6.63304 6.87907 6.56362 7.04667 6.56362 7.22144C6.56362 7.3962 6.63304 7.5638 6.75662 7.68737L9.57849 10.5027L6.75662 13.318C6.69511 13.379 6.64629 13.4516 6.61297 13.5316C6.57965 13.6115 6.5625 13.6973 6.5625 13.7839C6.5625 13.8706 6.57965 13.9563 6.61297 14.0363C6.64629 14.1163 6.69511 14.1889 6.75662 14.2499C6.81762 14.3114 6.89021 14.3602 6.97018 14.3935C7.05015 14.4268 7.13592 14.444 7.22256 14.444C7.30919 14.444 7.39496 14.4268 7.47493 14.3935C7.5549 14.3602 7.62749 14.3114 7.68849 14.2499L10.5038 11.428L13.3191 14.2499C13.3801 14.3114 13.4527 14.3602 13.5327 14.3935C13.6126 14.4268 13.6984 14.444 13.7851 14.444C13.8717 14.444 13.9575 14.4268 14.0374 14.3935C14.1174 14.3602 14.19 14.3114 14.251 14.2499C14.3125 14.1889 14.3613 14.1163 14.3946 14.0363C14.428 13.9563 14.4451 13.8706 14.4451 13.7839C14.4451 13.6973 14.428 13.6115 14.3946 13.5316C14.3613 13.4516 14.3125 13.379 14.251 13.318L11.4291 10.5027Z" fill="white" />
        </svg>
      </ActionIcon>
    </Action>
  )
}
