import React, {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {getCurrentUser} from "../services/app.service";
import Loading from "../components/Loading";

const App = React.createContext<{
    user?: any,
    setUser?: any,
    logout?: () => void,
    setLoading?: any,
    activity?: any,
    setActivity?: any,
    coords?: any,
    setCoords?: any,
    importPayload?: any,
    setImportPayload?:any
}>({});

export const AppProvider = ({children}: any) => {
    const [user, setUser] = useState<any>(null);
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(true);
    const [activity, setActivity] = useState<any>()
    const [coords, setCoords] = useState<Array<any>>([])
    const [importPayload, setImportPayload] = useState<any>()
 
    useEffect(() => {
        const run = async () => {
            const currentUser = await getCurrentUser().catch(reason => null);
            setUser(currentUser);
            setLoading(false);
        }
        run().finally(() => {
            setReady(true);
        });
    }, []);

    const logout = () => {
        setUser(null);
        Auth.signOut().then();
    }

    return (
        <App.Provider
            value={{
                user,
                setUser,
                logout,
                setLoading,
                activity,
                setActivity,
                coords,
                setCoords,
                importPayload,
                setImportPayload
            }}>
            {ready ? children : null}
            {loading ? <Loading/> : null}
        </App.Provider>
    )
}

export const useApp = () => React.useContext(App);