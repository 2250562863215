import styled from "styled-components";
import {Button} from "antd";

export const Action = styled.div`
  cursor: pointer;
  margin: 0 3px;
  padding-top: 40px;
`;
export const ActionIcon = styled.div``;
export const Copy = styled.div`
  position: absolute;
  top: 42px;
  cursor: pointer;
  right: 12px;
  background-color: white;
  padding-left: 12px;

  svg {
    width: 18px;
    height: 18px;
  }
`;
export const ContainerRows = styled.div`
  & > div {
    flex-wrap: nowrap;
    margin-top: -32px;
  }

  .row-0 {
    margin-top: 0;
  }

  .ant-form-item-label {
    opacity: 0;
  }

  .row-0 .ant-form-item-label {
    opacity: 1;
  }

  .space {
    flex: 1;
    max-width: 12px;
    width: 12px;
  }

  .ant-col-5 {
    flex: none;
    max-width: inherit;
    width: calc(25% - 10px);
  }

  .url-input {
    input {
      padding-right: 42px;
    }
  }
`;

export const AddButton = styled(Button)`
  height: 32px;
  background: #006561;
  border-radius: 30px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-left: 0px;
  border-color: rgba(255, 255, 255, 0) !important;

  & i {
    font-size: 14px;
    margin-right: 3px;
  }

  & svg {
    margin-right: 5px;
  }

  &:hover, &:active,&:focus {
    background: #006561 !important;
    color: #ffffff !important;
    opacity: 0.8;
  }
`;
