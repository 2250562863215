const Environment = {
    utilServiceUrl: process.env.REACT_APP_UTIL_SERVICE_URL || 'https://0k225rwb86.execute-api.ap-southeast-1.amazonaws.com/mobile',
    apiKey: process.env.REACT_APP_API_KEY || '',
    coreServiceUrl: process.env.REACT_APP_CORE_SERVICE_URL || 'https://ljkyxjuit3.execute-api.ap-southeast-1.amazonaws.com',
    appServiceUrl: process.env.REACT_APP_APP_SERVICE_URL || 'https://0k225rwb86.execute-api.ap-southeast-1.amazonaws.com',
    userPoolId: process.env.REACT_APP_USER_POOL_ID || 'ap-southeast-1_H1Obj42bf',
    userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '1pepqgum4rhek6s0lp6lhbp1ca',
    locationUrl: process.env.REACT_APP_LOCATION_URL || 'https://exo-core-dev-mainbucket-a3z1hbnal9cb.s3.ap-southeast-1.amazonaws.com',
    mapboxKey: process.env.REACT_APP_MAPBOX_KEY || 'pk.eyJ1IjoiZXhvdHJhaWxzIiwiYSI6ImNsODJnemJ5bTBweGgzd21rM3NkZHg1dTcifQ.Z5S3APmFZB4YZ19vEHlXwA',
    socialServiceUrl: process.env.REACT_APP_SOCIAL_SERVICE_URL || '	https://m2s948q1b3.execute-api.ap-southeast-1.amazonaws.com',
};

export default Environment
