import React, { useState } from 'react'
import { Container, ContainerView } from '../../components/Layout'
import Header from '../../components/Header'
import {
  ClickAble,
  ClickColAble,
  getTableId,
  LineLinkView,
  StatusView,
  TableStarView,
  TableTagActiveView,
  TableTagsView,
  TableTagView,
  TableThumbNullView,
  TableThumbView,
} from '../../components/Controls/table'
import { Line2View } from '../../components/Controls/table'
import {
  dateFormat,
  deleteTrail,
  getImageUrl,
  getTextAddress,
  listAttribute,
  listTrail,
  navDetail,
  round,
  truncate,
} from '../../services/app.service'
import { useNavigate } from 'react-router-dom'
import {
  FilterStatusOptions,
  TableActions,
  TableBody,
  TableFooter,
  TableHead,
  TableHeadFilterSelect,
  TableHeadFilterServiceSelect,
  TableProvider,
} from '../../components/Table'
import {
  AttributeProvider,
  ATTRIBUTES,
  FormAttributeSelectView,
  FormCategorySelectListView,
} from '../../components/Form/attribute'
import moment from 'moment'
import { message } from 'antd'
import Environment from '../../environment'

export const CATEGORY = {
  TRAIL: 'trail',
  MARATHON: 'marathon',
  EAT_LOCAL: 'eat_local',
  STAY: 'stay',
  ECO_ZONES: 'eco_zones',
  HERITAGE: 'heritage',
  SIGHTSEEING: 'sightseeing',
  ACTIVITY: 'activity',
}

export default function TrailList() {
  const navigate = useNavigate()

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer')
  }

  const copyLink = (url: string) => {
    navigator.clipboard.writeText(url)
    message.success('Copy Link success!')
  }

  const columns = [
    {
      title: 'Thumb',
      dataIndex: 'images',
      key: 'images',
      render: (text: any, record: any, index: number) => {
        return getImageUrl(record, 'feature_image', '') ? (
          <TableThumbView src={getImageUrl(record, 'feature_image', '')} />
        ) : (
          <TableThumbNullView />
        )
      },
    },
    {
      title: 'Name',
      dataIndex: 'name.keyword',
      sorter: true,
      render: (text: string, record: any, index: number) => {
        return (
          <ClickColAble
            onClick={() => {
              openInNewTab(`/trail/trails/update/${record.id}`)
            }}>
            <Line2View name={record['name']} value={getTextAddress(record)} />
          </ClickColAble>
        )
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      render: (text: string, record: any, index: number) => {
        const date = moment(record.createdAt)
        return (
          <Line2View
            text={date.format('hh:mm A')}
            value={date.format('DD/MM/YYYY')}
          />
        )
      },
    },
    {
      title: 'Attributes',
      dataIndex: 'attribute',
      key: 'attribute',
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            {record?.difficulty?.name ? (
              <TableTagActiveView
                onClick={() =>
                  navDetail(record?.difficulty?.id, openInNewTab, 'attribute')
                }
                className={record?.difficulty?.key || ''}>
                <svg
                  width='17'
                  height='9'
                  viewBox='0 0 17 9'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M17 7.32869C17 7.32869 17 4.82173 15.3 4.82173C14.722 4.82173 14.059 4.77994 13.362 4.67131C12.58 5.27298 11.475 5.65738 10.2 5.65738H9.9875L11.424 4.22841C11.1265 4.13649 10.8375 4.02785 10.5485 3.91086L8.925 5.50696C8.5935 5.44011 8.2875 5.34819 8.007 5.23955L9.7325 3.52646C9.4775 3.3844 9.231 3.24234 8.9845 3.07521L7.225 4.82173C6.97 4.64624 6.766 4.45404 6.5875 4.25348L8.313 2.5571C8.109 2.37326 7.905 2.1727 7.718 1.96379L6.1285 3.52646C6.0265 3.26741 5.95 3.00836 5.95 2.73259C5.95 2.02228 6.3325 1.38719 6.936 0.877437C6.8935 0.802229 6.8425 0.727019 6.8 0.643454L5.4995 0C4.777 0.785515 3.281 1.3454 1.4875 1.45404L1.445 1.47911H0.85C0.85 1.47911 0 2.31476 0 5.23955C0 5.71588 0 6.1337 0.0340004 6.49304H2.55C3.485 6.49304 4.607 6.71031 5.695 6.91086C6.698 7.12813 7.735 7.32869 8.5 7.32869H17ZM0.85 9C0.85 9 0.356998 9 0.118999 7.32869H2.55C4.25 7.32869 6.8 8.16435 8.5 8.16435H16.762C16.473 8.66574 15.929 9 15.3 9H0.85Z'
                    fill='white'
                  />
                </svg>
                {record?.difficulty?.name || ''}
              </TableTagActiveView>
            ) : null}
            <TableTagsView>
              {record?.activity?.name ? (
                <TableTagView
                  onClick={() =>
                    navDetail(record?.activity?.id, openInNewTab, 'attribute')
                  }>
                  {record?.activity?.name || ''}
                </TableTagView>
              ) : null}
              {record?.route_type?.name ? (
                <TableTagView
                  onClick={() =>
                    navDetail(record?.route_type?.id, openInNewTab, 'attribute')
                  }>
                  {record?.route_type?.name || ''}
                </TableTagView>
              ) : null}
            </TableTagsView>
          </div>
        )
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner_info',
      key: 'owner_info',
      render: (text: string, record: any, index: number) => {
        return record?.owner_info ? (
          <ClickAble
            onClick={() =>
              navDetail(
                record?.owner_info?.id,
                openInNewTab,
                record?.owner_info?.group?.toLowerCase().includes('admin')
                  ? 'admin'
                  : 'user'
              )
            }>
            <Line2View
              color={'#006561'}
              name={record?.owner_info?.full_name || ''}
              value={record?.owner_info?.email || ''}
            />
          </ClickAble>
        ) : null
      },
    },
    {
      title: 'Rate',
      dataIndex: 'avg_rate',
      key: 'avg_rate',
      sorter: true,
      render: (text: string, record: any, index: number) => {
        return (
          <TableStarView>
            <i className='fa-solid fa-star' />
            {round(record?.avg_rate || 0, 1)}({record?.total_vote || 0})
          </TableStarView>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: any, index: number) => {
        return <StatusView value={text} />
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: any, index: number) => {
        return (
          <TableActions
            edit={{
              success: () => {
                openInNewTab(`/trail/trails/update/${record.id}`)
              },
            }}
            remove={{
              title: 'Are you sure to remove this trail?',
              id: record.id,
            }}
          />
        )
      },
    },
  ]

  return (
    <Container>
      <Header
        // contentComponent={
        //   <div
        //     style={{
        //       marginRight: '10px',
        //       display: 'flex',
        //     }}
        //   >
        //     <div
        //       style={{
        //         marginRight: '10px',
        //       }}
        //     >
        //       <ClickColAble
        //         onClick={() => {
        //           copyLink(Environment.trail)
        //         }}
        //         style={{
        //           marginBottom: '8px',
        //         }}
        //       >
        //         <LineLinkView
        //           value={'Full Trails'}
        //           color={'#1890ff'}
        //           style={{
        //             display: 'flex',
        //           }}
        //         />
        //       </ClickColAble>
        //       <ClickColAble
        //         onClick={() => {
        //           copyLink(Environment.ecoZones)
        //         }}
        //       >
        //         <LineLinkView
        //           value={'Full Eco-zones'}
        //           color={'#1890ff'}
        //           style={{
        //             display: 'flex',
        //           }}
        //         />
        //       </ClickColAble>
        //     </div>
        //     <div>
        //       <ClickColAble
        //         onClick={() => {
        //           copyLink(Environment.heritage)
        //         }}
        //         style={{
        //           marginBottom: '8px'
        //         }}
        //       >
        //         <LineLinkView
        //           value={'Full Heritage'}
        //           color={'#1890ff'}
        //           style={{
        //             display: 'flex',
        //           }}
        //         />
        //       </ClickColAble>
        //       <ClickColAble
        //         onClick={() => {
        //           copyLink(Environment.sightseeing)
        //         }}
        //       >
        //         <LineLinkView
        //           value={'Full Sightseeing'}
        //           color={'#1890ff'}
        //           style={{
        //             display: 'flex',
        //           }}
        //         />
        //       </ClickColAble>
        //     </div>
        //   </div>
        // }
        onCreate={() => {
          navigate('/trail/trails/create')
        }}
      />
      <ContainerView>
        <TableProvider
          initQuery={{ category: CATEGORY.TRAIL }}
          paging
          listService={listTrail}
          removeService={deleteTrail}>
          <AttributeProvider>
            <TableHead
              title={'${COUNT} records found'}
              showExport={true}
              filters={[
                {
                  name: 'category',
                  control: (
                    <FormCategorySelectListView
                      allowClear
                      placeholder={'Category'}
                      options={[
                        { value: CATEGORY.ECO_ZONES, label: 'Eco-zones' },
                        { value: CATEGORY.HERITAGE, label: 'Heritage' },
                        { value: CATEGORY.SIGHTSEEING, label: 'Sightseeing' },
                      ]}
                    />
                  ),
                },
                {
                  name: 'activity',
                  control: (
                    <FormAttributeSelectView
                      allowClear
                      placeholder={'Activity'}
                      category={ATTRIBUTES.activity_types}
                    />
                  ),
                },
                {
                  name: 'difficulty',
                  control: (
                    <FormAttributeSelectView
                      allowClear
                      placeholder={'Difficulty'}
                      category={ATTRIBUTES.difficulty}
                    />
                  ),
                },
                {
                  name: 'route_type',
                  control: (
                    <FormAttributeSelectView
                      allowClear
                      placeholder={'Route Type'}
                      category={ATTRIBUTES.route_type}
                    />
                  ),
                },
                {
                  name: 'status',
                  control: (
                    <TableHeadFilterSelect
                      allowClear
                      placeholder={'Status'}
                      options={[
                        ...FilterStatusOptions,
                        { label: 'Draft', value: 'Draft' },
                        { label: 'InReview', value: 'InReview' },
                        { label: 'Rejected', value: 'Rejected' },
                      ]}
                    />
                  ),
                },
              ]}
            />
          </AttributeProvider>
          <TableBody rowKey={'id'} columns={columns} />
          <TableFooter />
        </TableProvider>
      </ContainerView>
    </Container>
  )
}
