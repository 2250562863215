import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import moment from "moment";
import { Auth } from "aws-amplify";
import Environment from "../environment";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

const createAxiosService = (url: string) => {
    const axiosInstance = axios.create({
        baseURL: url,
    });
    axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
        // @ts-ignore
        const user: any = window?.user;
        if (user?.AuthenticationResult?.AccessToken) {
            // @ts-ignore
            request.headers['Authorization'] = `Bearer ${user?.AuthenticationResult?.AccessToken}`;
        }
        return request;
    });

    axiosInstance.interceptors.response.use(async (response: AxiosResponse) => {
        if (response.status === 401) {
            // Logout
            await Auth.signOut();
            window.location.reload();
        }
        return response;
    }, async (error) => {
        if (error.response) {
            if (error.response?.status === 401) {
                await Auth.signOut();
                window.location.reload();
            }
            return {
                status: error.response?.status || 400,
                data: error.response?.data,
            };
        }

        return Promise.reject(error);
    });
    return axiosInstance;
}

export const coreService = createAxiosService(Environment.coreServiceUrl);
export const appService = createAxiosService(Environment.appServiceUrl);
export const socialService = createAxiosService(Environment.socialServiceUrl);
const utilService = createAxiosService('https://53stwiz7gd.execute-api.ap-southeast-1.amazonaws.com');

export const putOverride = (trail_id: string, activity_id: string) => {
    return appService.put(`/mobile/trail-map/${trail_id}/override`, { activity_id })
}

export const elevationCalc = (points: any) => {
    return appService.post(`/trail-map/calc-elevation`, {
        coordinates: points, planned_elevation: []
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function formatValues(values: any) {
    const result: any = {};
    if (values) {
        for (const valuesKey in values) {
            result[valuesKey] = values[valuesKey];
            if (valuesKey && (typeof values[valuesKey]) === 'string') {
                result[valuesKey] = (values[valuesKey] || "").toString().trim();
            }

        }
    }
    return result;
}

export const duration = (time: number, unit: any) => {
    const item = moment.duration(time, unit);
    return `${item.hours()}h ${item.minutes()}m `
}

export const durationActivity = (time: number, unit: any) => {
    const addZero = (num: number) => {
        return num < 10 ? `0${num}` : num;
    }

    const item = moment.duration(time, unit);
    if (item.hours()) {
        return `${addZero(item.hours())}:${addZero(item.minutes())}:${addZero(item.seconds())}`;
    }
    return `${addZero(item.minutes())}:${addZero(item.seconds())}`;
}

export function numberWithSpaces(x: any) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function round(value: any, exp: number) {
    if (typeof exp === 'undefined' || +exp === 0)
        return Math.round(value);

    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
        return NaN;

    // Shift
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}

export const sleep = async (timer: number) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(true);
        }, timer)
    })
}

export const validateMessages = {
    required: "${label} is required!",
};

export const getTextAddress = (record: any, expand: boolean = true) => {
    if (typeof record?.location === 'object') {
        record.location = "";
    }
    return (record?.location || record?.address) && expand ? (record?.location || record?.address) : `${record?.district || ""}${record?.district ? "," : ""} ${record?.province || ""}${record?.province ? "," : ""} ${record?.country || ""}`
}

export function dateFormat(date: any) {
    return moment(date).format('HH:mm:ss DD/MM/YYYY');
}

export function getImageUrl(record: any, param: string, backupImage: string) {
    if (record?.state?.toLowerCase() === 'completed') {
        return record?.state?.toLowerCase() === 'completed' && record[param] ? record[param]?.thumbnail || record[param]?.normal || record[param]?.origin : backupImage;
    }
    return record[param]?.origin || backupImage || "";
}

export function validatePassword() {
    return {
        validator: async (rule: any, value: any, callback: any) => {
            if (value) {
                const password = value;
                const passwordChecker = () => {
                    return {
                        number: !password || !password.replace(/[^0-9]/g, '')?.toString() || (password === password.replace(/[^0-9]/g, '')?.toString()),
                        mixed: !(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(password),
                        case: (password.toUpperCase() === password) || (password.toLowerCase() === password),
                        minimum: password.length < 8
                    }
                }
                if (passwordChecker().minimum) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
                if (passwordChecker().number) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
                if (passwordChecker().mixed) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
                if (passwordChecker().case) {
                    throw new Error('Password minimum 8 characters, at least one uppercase letter, one lowercase letter and one number');
                }
            }
        }
    }
}

export const getCurrentUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser().catch(reason => null);
    let result = currentUser?.signInUserSession?.accessToken?.jwtToken ? {
        attributes: currentUser?.attributes,
        AuthenticationResult: {
            AccessToken: currentUser?.signInUserSession?.accessToken?.jwtToken,
            IdToken: currentUser?.signInUserSession.idToken?.jwtToken,
            RefreshToken: currentUser?.signInUserSession?.refreshToken?.token,
            ExpiresIn: currentUser?.signInUserSession?.accessToken?.payload?.exp,
        },
        groups: currentUser?.signInUserSession?.accessToken?.payload["cognito:groups"]
    } : null;
    if (result && result?.groups?.includes("Administrators")) {
        // @ts-ignore
        window.user = result;
        // @ts-ignore
        const { data, status } = await getAdminProfile().catch(reason => {
            return { data: null, status: 500 }
        });
        if (status === 200) {
            result = { ...result, ...(data?.data || {}) };
        }
        return result;
    }
    return null;
}

export const postLogin = async (user: any) => {
    const currentAuthConfig = Auth.configure();
    Auth.configure({
        ...currentAuthConfig,
        storage: (user?.remember ? localStorage : sessionStorage)
    });
    const result = await Auth.signIn(user?.email?.toString().toLocaleLowerCase(), user.password).catch(
        (reason: any) => {
            return {
                error: true,
                message: reason.message,
            };
        }
    );
    if (result.error) {
        const resultData: any = {
            data: {
                message: result.message,
            },
            status: 400,
        };
        return resultData;
    }

    if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
        return { status: 201, data: result }
    }

    const resultData: any = {
        data: {
            attributes: result.attributes,
            AuthenticationResult: {
                AccessToken: result.signInUserSession.accessToken.jwtToken,
                IdToken: result.signInUserSession.idToken.jwtToken,
                RefreshToken: result.signInUserSession.refreshToken.token,
                ExpiresIn: result.signInUserSession.accessToken.payload.exp,
            },
            groups: result.signInUserSession.accessToken.payload["cognito:groups"]
        },
        status: 200,
    };
    return resultData;
};

export const postSentOtp = async (user: any) => {
    const result = await Auth.forgotPassword(user.email).catch(
        (reason: any) => {
            return {
                error: true,
                message: reason.message,
            };
        }
    );
    if (result.error) {
        const resultData: any = {
            data: {
                message: result.message,
            },
            status: 400,
        };
        return resultData;
    }
    return result;
};

export const postChangePassword = async (user: any, session: any) => {

    const result: any = await Auth.completeNewPassword(session, user.password).catch(
        (reason: any) => {
            return {
                error: true,
                message: reason.message,
            };
        }
    );
    if (result.error) {
        const resultData: any = {
            data: {
                message: result?.message || "",
            },
            status: 400,
        };
        return resultData;
    }
    return result;
};

export const postResetPassword = async (user: any, session: any) => {
    const result: any = await Auth.forgotPasswordSubmit(session.email, user.code, user.password).catch(
        (reason: any) => {
            return {
                error: true,
                message: reason.message,
            };
        }
    );
    if (result.error) {
        const resultData: any = {
            data: {
                message: result?.message || "",
            },
            status: 400,
        };
        return resultData;
    }
    return result;
};

export const postUploadText = async (file: any, folder: string = "temp") => {
    const result = await coreService.get('/upload-url', {
        params: {
            folder: folder,
            extension: 'json'
        }
    });

    if (result.status === 200 && result?.data?.data) {
        return axios.put(result.data.data, file, {
            headers: {
                "Content-Type": 'application/json',
            }
        }).then(value => {
            return {
                status: value.status,
                data: result.data.data.toString().split('?')[0]
            };
        })
    }
}

export const uploadKML = async (file: any) => {
    const formData = new FormData()
    formData.append("", file)
    return coreService.post("/upload", formData, {
        headers: {
            "Content-Type": file.type,
        }
    })
}

export const getImportedList = (trailId: string) => {
    return appService.get(`/trail-map/${trailId}/import-history`)
}

export const saveImport = (trailId: string, filename: string) => {
    return appService.post(`/trail-map/${trailId}/import-history`, { name: filename })
}

export const getAppVersion = () => {
    return appService.get(`/app-version`)
}

export const postAppVersion = ({ version, message, force_update }: { version: string, message: string, force_update: boolean }) => {
    return appService.post(`/app-version`, {
        version, message, force_update
    })
}

export const postUpload = async (file: any, direct: boolean = false, folder: string = "temp") => {
    if (direct) {
        const formdata = new FormData();
        formdata.append("", file);
        return coreService.post("/upload", formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(result => {
            if (result.status === 200) {
                return {
                    status: 200,
                    data: (result?.data?.data[0] || "")
                };
            }
            return result;
        });
    } else {
        const fs = await new Promise(resolve => {
            let reader = new FileReader()
            reader.onload = (e: any) => {
                resolve(e.target.result);
            }
            reader.readAsArrayBuffer(file);
        });
        const result = await coreService.get('/upload-url', {
            params: {
                folder: folder,
                extension: file.name.toString().split('.').pop()
            }
        });

        if (result.status === 200 && result?.data?.data) {
            return axios.put(result.data.data, fs, {
                headers: {
                    "Content-Type": file.type,
                }
            }).then(value => {
                return {
                    status: value.status,
                    data: result.data.data.toString().split('?')[0]
                };
            })
        }
    }
}

const getUploadUrls = async (files: Array<File>) => {
    const result = await coreService.post('/upload-url', files.map(file => {
        return {
            name: file.name,
            type: file.type
        }
    }));
    if (result.status === 200) {
        return result?.data?.data || []
    }
    return [];
}

const getFileBuffer = async (file: File) => {
    return await (new Promise(resolve => {
        let reader = new FileReader()
        reader.onload = (e: any) => {
            resolve(e.target.result);
        }
        reader.readAsArrayBuffer(file);
    }));
}

const putS3File = async (file: File, uri: string, fileUrl: string) => {
    const fs = await getFileBuffer(file);
    if (fs) {
        return axios.put(uri, fs, {
            headers: {
                "Content-Type": file.type,
            }
        }).then(value => {
            return {
                status: value.status,
                data: fileUrl
            };
        }).catch(reason => {
            return {
                status: 500,
                data: null
            };
        })
    }
    return null;
}

export const postUploads = async (files: Array<File>) => {
    const uploadUrls = await getUploadUrls(files);
    const result = [];
    if (uploadUrls.length === files.length) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileUrl = uploadUrls[i]?.url;
            const uploadUrl = uploadUrls[i]?.uploadUrl;
            const promise = putS3File(file, uploadUrl, fileUrl);
            result.push(promise);
        }
    }
    return result;
}

export const getAdminProfile = () => {
    return coreService.get('/admin/profile');
}

export const getAdmin = (id: string) => {
    return coreService.get(`/admin/${id}`);
}
export const listAdmin = (params: any) => {
    return coreService.get(`/admin`, { params: params });
}
export const deleteAdmin = (id: string) => {
    return coreService.delete(`/admin/${id}`);
}
export const putAdmin = (id: string, data: any) => {
    return coreService.put(`/admin/${id}`, data);
}
export const postAdmin = (data: any) => {
    return coreService.post(`/admin`, data);
}

export const getUser = (id: string) => {
    return coreService.get(`/users/${id}`);
}
export const listUser = (params: any) => {
    return coreService.get(`/users`, { params: params });
}
export const listUserByIds = (ids: any) => {
    return coreService.get(`/users/list-by-ids`, {
        params: {
            ids: (ids || []).join(',')
        }
    });
}
export const deleteUser = (id: string) => {
    return coreService.delete(`/users/${id}`);
}
export const putUser = (id: string, data: any) => {
    return coreService.put(`/users/${id}`, data);
}
export const postUser = (data: any) => {
    return coreService.post(`/users`, data);
}

export const getAttribute = (id: string) => {
    return appService.get(`/attribute/${id}`);
}
export const listAttribute = (params: any) => {
    return appService.get(`/attribute`, { params: params });
}
export const deleteAttribute = (id: string) => {
    return appService.delete(`/attribute/${id}`);
}
export const putAttribute = (id: string, data: any) => {
    return appService.put(`/attribute/${id}`, data);
}
export const postAttribute = (data: any) => {
    return appService.post(`/attribute`, data);
}

export const getLocation = (country: string) => {
    return axios.get(`${Environment.locationUrl}/country/${country}.json`);
}

export const truncate = (str: string, n: number) => {
    return (str?.length > n) ? str.slice(0, n - 1) + '...' : (str || "");
};

export const getJsonContent = (uri: string) => {
    return axios.get(uri);
}

export const geoJsonConverter = (values: any, type: 'in' | 'out') => {
    if (type === 'out') {
        return {
            "coordinates": values.coordinates || [],
            "planned_elevation": values.planned_elevation || [],
            "guides": values.guides || [],
            "splits": values?.splits?.length >= 0 ? values?.splits : undefined,
        }
    } else {
        return {
            "coordinates": values?.coordinates || [],
            "planned_elevation": values?.planned_elevation || [],
            "guides": values.guides || [],
            "splits": values?.splits?.length >= 0 ? values?.splits : undefined,
        }
    }
}

export const navDetail = (id: string, navigate: any, type: string) => {
    // let route = `/user/customers/update/${id}`;
    // if (type === 'admin') {
    //     route = `/user/admins/update/${id}`;
    // }
    // if (type === 'attribute') {
    //     route = `/trail/attributes/update/${id}`;
    // }
    // if (type === 'trail') {
    //     route = `/trail/trails/update/${id}`;
    // }
    // navigate(route);
}

export const getTrail = (id: string) => {
    return appService.get(`/trail/${id}`);
}
export const getTrailCreatorById = (id: string) => {
    return appService.get(``)
}
export const getTrailAttribute = (id: string) => {
    return appService.get(`/trail-attribute/${id}`);
}
export const getTrailMap = (id: string) => {
    return appService.get(`/trail-map/${id}`);
}
export const getTrailOwner = (id: string) => {
    return appService.get(`/trail-owner/${id}`);
}
export const listTrail = (params: any) => {
    return appService.get(`/trail`, { params: params });
}
export const listTrailByIds = (ids: any) => {
    return appService.get(`/trail/list-by-ids`, {
        params: {
            ids: (ids || []).join(',')
        }
    });
}

export const getUpdateTrailHistory = (id: string) => {
    return appService.get(`/trail-map/${id}/histories`)
}

export const restoreHistory = (trail_id: string, history_id: string) => {
    return appService.put(`/trail-map/${trail_id}/restore`, { history_id })
}

export const deleteTrail = (id: string) => {
    return appService.delete(`/trail/${id}`);
}
export const putTrail = (id: string, data: any) => {
    return appService.put(`/trail/${id}`, data);
}
export const postTrail = (data: any) => {
    return appService.post(`/trail`, data);
}
export const putTrailAttribute = (id: string, data: any) => {
    return appService.put(`/trail-attribute/${id}`, { ...data, trail_id: id });
}
export const postTrailOwner = (id: string, data: any) => {
    return appService.post(`/trail-owner`, { trail_id: id });
}
export const putTrailMap = (id: string, data: any) => {
    return appService.put(`/trail-map/${id}`, { ...data });
}
export const putOverrideTrailMapAsActivity = (trail_id: string, activity_id: string) => {
    return appService.put(`/trail-map/${trail_id}`, { activity_id })
}
export const getAddress = (location: any) => {
    const address = window.encodeURIComponent(`${location?.district}, ${location?.province}, ${location?.country}`);
    return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country${location.country}&access_token=${Environment.mapboxKey}`);
}


export const getReview = (id: string) => {
    return appService.get(`/reviews/${id}`);
}
export const listReview = (params: any) => {
    return appService.get(`/reviews`, { params: params });
}
export const deleteReview = (id: string) => {
    return appService.delete(`/reviews/${id}`);
}
export const putReview = (id: string, data: any) => {
    return appService.put(`/reviews/${id}`, data);
}
export const postReview = (data: any) => {
    return appService.post(`/reviews`, data);
}

export const getPhoto = (id: string) => {
    return appService.get(`/photos/${id}`);
}
export const listPhoto = (params: any) => {
    return appService.get(`/photos`, { params: params });
}
export const deletePhoto = (id: string) => {
    return appService.delete(`/photos/${id}`);
}
export const putPhoto = (id: string, data: any) => {
    return appService.put(`/photos/${id}`, data);
}
export const postPhoto = (data: any) => {
    return appService.post(`/photos`, data);
}

export const getActivity = (id: string) => {
    return appService.get(`/activity/${id}`);
}
export const listActivity = (params: any) => {
    if (!params.status) {
        params.status = 'Active,Inactive,Verified'
    }
    return appService.get(`/activity`, { params: params });
}
export const deleteActivity = (id: string) => {
    return appService.delete(`/activity/${id}`);
}
export const putActivity = (id: string, data: any) => {
    return appService.put(`/activity/${id}`, data);
}
export const postActivity = (data: any) => {
    return appService.post(`/activity`, data);
}
export const getSettingAds = (id: string) => {
    return appService.get(`/advertising/${id}`);
}
export const listSettingAds = () => {
    return appService.get(`/advertising`);
}
export const putSettingAds = (id: string, data: any) => {
    return appService.put(`/advertising/${id}`, data);
}
export const postSettingAds = (data: any) => {
    return appService.post(`/advertising`, data);
}


export const getCrawl = (id: string) => {
    return appService.get(`/crawl/${id}`);
}
export const listCrawl = (params: any) => {
    return appService.get(`/crawl`, { params: params });
}
export const listCrawlRegions = (params: any) => {
    return appService.get(`/crawl/source-regions`, { params: {} }).then(value => {
        value.data.data.items = value.data.data.map((x: string) => {
            return {
                id: x,
                name: x
            }
        })
        console.log(value);
        return value;
    });

}
export const listCrawlSources = (params: any) => {
    return appService.get(`/crawl/sources`, { params: {} }).then(value => {
        value.data.data.items = value.data.data;
        console.log(value);
        return value;
    });
}
export const deleteCrawl = (id: string) => {
    return appService.delete(`/crawl/${id}`);
}
export const putCrawl = (id: string, data: any) => {
    return appService.put(`/crawl/${id}`, data);
}
export const putCrawlReject = (id: string) => {
    return appService.put(`/crawl/${id}/reject`, {});
}
export const putCrawlCancel = (id: string) => {
    return appService.put(`/crawl/${id}/cancel`, {});
}
export const putCrawlAccept = (id: string) => {
    return appService.put(`/crawl/${id}/accept`, {});
}
export const putCrawlRestore = (id: string) => {
    return appService.put(`/crawl/${id}/restore`, {});
}
export const putCrawlMigrate = (id: string) => {
    return appService.put(`/crawl/${id}/migrate`, {});
}
export const putCrawlRollback = (id: string) => {
    return appService.put(`/crawl/${id}/rollback`, {});
}
export const postCrawl = (data: any) => {
    return appService.post(`/crawl`, data);
}
export const listChallenge = (params: any) => {
    return socialService.get(`/challenge`, { params: params });
}
export const getChallenge = (id: string) => {
    return socialService.get(`/challenge/${id}`);
}

export const getConfigSetting = () => {
    return socialService.get(`/config-setting`);
}
export const postConfigSetting = (data: any) => {
    return socialService.post(`/config-setting`, data);
}
export const putConfigSetting = (id: string, data: any) => {
    return socialService.put(`/config-setting/${id}`, data);
}
export const deleteConfigSetting = (id: string) => {
    return socialService.delete(`/config-setting/${id}`);
}

export const getConfigNotify = () => {
    return socialService.get(`/notify-schedule`)
}
export const deleteConfigNotify = (id: string) => {
    return socialService.delete(`/notify-schedule/${id}`);
}
export const putConfigNotify = (id: string, data: any) => {
    return socialService.put(`/notify-schedule/${id}`, data);
}
export const postConfigNotify = (data: any) => {
    return socialService.post(`/notify-schedule`, data);
}