import { Col, Form, Row, message } from 'antd'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import {
  FormItem,
  InputDateTimeFormView,
  InputFormView2,
  SelectFormView2FormView,
  TabEditView,
} from '../../../components/Form/index.'
import Header from '../../../components/Header'
import { Container, ContentViewLayout } from '../../../components/Layout'
import { TableActions } from '../../../components/Table'
import { useApp } from '../../../contexts/App'
import {
  deleteConfigNotify,
  formatValues,
  getConfigNotify,
  postConfigNotify,
  putConfigNotify,
  validateMessages,
} from '../../../services/app.service'
import { AddButton, ContainerRows } from '../ads/style'
import { SelectView } from '../../../components/Controls'

export default function NotificationConfig() {
  const [row, setRow] = useState<any>({})
  const [form] = Form.useForm()
  const { setUser, user, setLoading } = useApp()
  const currentRef = useRef<any>()
  const [tab, setTab] = useState('setting')
  const [visible, setVisible] = useState<boolean>(false)
  const [appSetting, setAppSetting] = useState<any>([])
  const [editValue, setEditValue] = useState<any>()

  useEffect(() => {
    loadDataSetting()
  }, [])
  const loadDataSetting = async () => {
    const response = await getConfigNotify()
    setAppSetting(response?.data?.data?.items)
    let itemsList = response?.data?.data?.items
    itemsList = itemsList.sort((a: any, b: any) => {
      if (a?.notify_time < b?.notify_time) {
        return -1
      }
      if (a?.notify_time > b?.notify_time) {
        return 1
      }
      return 0
    })
    const result = { ...row, notification: itemsList }
    setRow(result)
    form.setFieldsValue(result)
  }

  const onAdd = async (values: any, isNotifyTime?: any) => {
    values = formatValues(values)
    if (!values?.content_vi && !values?.content_en) {
      message.error('Content is require!')
      return
    }
    if (values?.mode_notify && values?.mode_notify?.length <= 0) {
      message.error('Mode of Notification is require!')
      return
    }
    if (!values?.notify_time) {
      message.error('Sending schedule invalid !')
      return
    }
    if (values?.notify_time && isNotifyTime) {
      let isBefore = moment(values?.notify_time).isBefore(moment().add(29, 'm'))
      if (isBefore) {
        message.error('Schedule sending minimum 30 minutes!')
        return
      }
    }
    setLoading(true)
    let newAppSetting
    if (editValue) {
      let newSetting = appSetting
        ? appSetting.find((item: any) => {
          return item?.id === editValue?.id
        })
        : {}
      newAppSetting = {
        ...newSetting,
        ...values,
        content_vi: values?.content_vi === '' ? undefined : values?.content_vi,
        content_en: values?.content_en === '' ? undefined : values?.content_en,
        mode_notify: values?.mode_notify,
        notify_time: moment(values?.notify_time).format('DD/MM/YYYY HH:mm'),
      }
      const { data, status } = await putConfigNotify(editValue?.id, {
        ...newAppSetting,
      })
      if (status === 200 && data?.data) {
        message.success('Update success!')
        setVisible(false)
      } else {
        message.error(data?.message)
      }
    } else {
      newAppSetting = {
        ...values,
        content_vi: values?.content_vi === '' ? undefined : values?.content_vi,
        content_en: values?.content_en === '' ? undefined : values?.content_en,
        mode_notify: values?.mode_notify,
      }
      const { data, status } = await postConfigNotify({
        ...newAppSetting,
      })
      if (status === 200 && data?.data) {
        message.success('Create success!')
        setVisible(false)
      } else {
        message.error(data?.message)
      }
    }
    loadDataSetting()
    setLoading(false)
  }

  const onRemove = async (value: any) => {
    setLoading(true)
    const { data, status } = await deleteConfigNotify(value?.id)
    if (status === 200) {
      message.success('Remove success!')
    } else {
      message.error(data?.message)
    }
    loadDataSetting()
    setLoading(false)
  }

  return (
    <Container>
      <Header />
      <Form
        form={form}
        validateMessages={validateMessages}
        onFinishFailed={(e) => {
          message.error('Please complete all required fields!')
        }}
      >
        <ContentViewLayout
          header={null}
          body={
            <TabEditView
              onChange={(value) => {
                setTab(value)
              }}
            >
              <TabEditView.TabPane
                destroyInactiveTabPane={false}
                forceRender
                key={'notification'}
                tab={'Notification'}
              >
                <Form.List name={'notification'}>
                  {(fields, { add, remove }, { errors }) => {
                    return (
                      <ContainerRows>
                        {fields.map((field, index) => {
                          let value = form.getFieldValue([
                            'notification',
                            field.name,
                          ])
                          let notify_time = value?.notify_time
                          return (
                            <Row
                              key={index.toString()}
                              className={`row-0`}
                              style={{ marginBottom: '32px' }}
                            >
                              <Col span={7}>
                                <Row style={{ width: '100%' }}>
                                  <FormItem
                                    name={[field.name, 'content_en']}
                                    label={'Content (EN)'}
                                    style={{ width: '100%' }}
                                  >
                                    <InputFormView2
                                      valueInput={value}
                                      onBlur={(event: any) => {
                                        let value2 = form.getFieldValue([
                                          'notification',
                                          field.name,
                                        ])
                                        if (
                                          value?.content_en !==
                                          value2?.content_en
                                        ) {
                                          onAdd({
                                            ...value2,
                                          })
                                        }
                                      }}
                                    />
                                  </FormItem>
                                  <FormItem
                                    name={[field.name, 'content_vi']}
                                    label={'Nội dung (VI)'}
                                    style={{ width: '100%' }}
                                  >
                                    <InputFormView2
                                      valueInput={value}
                                      onBlur={(event: any) => {
                                        let value2 = form.getFieldValue([
                                          'notification',
                                          field.name,
                                        ])
                                        if (
                                          value?.content_vi !==
                                          value2?.content_vi
                                        ) {
                                          onAdd({
                                            ...value2,
                                          })
                                        }
                                      }}
                                    />
                                  </FormItem>
                                </Row>
                              </Col>
                              <Col className={'space'} span={1} />
                              <Col
                                span={7}
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Row style={{ width: '100%' }}>
                                  <FormItem
                                    name={[field.name, 'mode_notify']}
                                    label={'Mode of Notification'}
                                    style={{ width: '100%' }}
                                  >
                                    <SelectFormView2FormView
                                      valueInput={value}
                                      onBlur={(event: any) => {
                                        let value2 = form.getFieldValue([
                                          'notification',
                                          field.name,
                                        ])
                                        if (value?.mode_notify !== value2?.mode_notify) {
                                          onAdd({
                                            ...value2
                                          })
                                        }
                                      }}
                                      onChange={(values: any) => {
                                        form.setFieldValue(["notification", field.name, "mode_notify"], values)
                                      }}
                                    >
                                      <SelectView.Option value={'in_app'}>
                                        In app list
                                      </SelectView.Option>
                                      <SelectView.Option value={'push_notify'}>
                                        Push notification
                                      </SelectView.Option>
                                      <SelectView.Option value={'popup'}>
                                        Pop up in app
                                      </SelectView.Option>
                                    </SelectFormView2FormView>
                                  </FormItem>
                                </Row>
                              </Col>
                              <Col className={'space'} span={1} />
                              <Col
                                span={7}
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Row style={{ width: '100%' }}>
                                  <FormItem
                                    name={[field.name, 'notify_time']}
                                    label={'Sending schedule'}
                                    style={{ width: '100%' }}
                                  >
                                    <InputDateTimeFormView
                                      valueInput={value}
                                      onBlur={(event: any) => {
                                        let value2 = form.getFieldValue([
                                          'notification',
                                          field.name,
                                        ])
                                        if (value?.notify_time !== value2?.notify_time) {
                                          onAdd({
                                            ...value2
                                          }, true)
                                        }
                                      }}
                                    />
                                  </FormItem>
                                </Row>
                              </Col>
                              <Col className={'space'} span={1} />
                              <FormItem
                                label={' '}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{ marginTop: '6px' }}>
                                  <TableActions
                                    onRemove={() => {
                                      if (value?.id) {
                                        onRemove(value)
                                      }
                                      remove(field.name)
                                    }}
                                    remove={{
                                      title:
                                        'Are you sure to remove this notification?',
                                      id: value?.id,
                                    }}
                                  />
                                </div>
                              </FormItem>
                            </Row>
                          )
                        })}
                        <AddButton onClick={() => add()} htmlType={'button'}>
                          <i className='fa-solid fa-plus' />
                          Add new notification
                        </AddButton>
                      </ContainerRows>
                    )
                  }}
                </Form.List>
              </TabEditView.TabPane>
            </TabEditView>
          }
          hiddenRightContent={true}
        />
      </Form>
    </Container>
  )
}
