import styled from "styled-components";
import {Pagination} from "antd";

export const TableHeadContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const TableHeadTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #006561;
`;
export const TableHeadFilter = styled.div`

  & > form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 8px;
  }

  & .ant-btn-default[type="submit"] {
    height: 32px;
    background-color: #958179;
    border-color: #958179;
    border-radius: 30px;
    padding: 0 18px;
    color: white;
    margin-left: 12px;
  }

  & .ant-form-item {
    margin-bottom: 0;
    min-width: 100px;

    .ant-select-selector {
      height: 32px !important;
      background: #FFFFFF;
      border: 1.5px solid #D1D1D1;
      border-radius: 100px !important;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }

    .ant-select-selection-item {
      line-height: 29px !important;
    }
  }

  & .filters {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.search {
      padding-right: 12px;
      margin-right: 4px;
    }

    &.search:after {
      content: '';
      position: absolute;
      width: 1.5px;
      height: 20px;
      background-color: #cacaca;
      right: 0;
      top: 6px;
    }

    & > * {
      margin-left: 12px;
    }

    & .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
      height: 30px !important;
      line-height: 28px;
    }
  }

  & .search-export {
      margin-left: 4px;
      & .ant-btn {
        height: 32px;
        background-color: #958179;
        border-color: #958179;
        border-radius: 30px;
        padding: 0 18px;
        color: white;
      }
  }

  & .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & input {
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 30px;
      padding-left: 34px;
    }

    .search-form {
      position: relative;
    }

    .search-form > svg {
      position: absolute;
      z-index: 1;
      top: 9px;
      left: 11px;
    }
  }
`;
export const TableFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;
export const TableFooterSize = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > span {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }

  .ant-select-selector {
    border: 1px solid #CCCCCC !important;
    border-radius: 3px !important;
  }

  .ant-select {
    margin: 0 12px;
  }
`;
export const TableFooterPagination = styled(Pagination)`
  .ant-pagination-item-active a, .ant-pagination-item:hover, .ant-pagination-item:hover a {
    color: #006561;
    border-color: #006561;
  }

  .ant-pagination-item-active {
    border-color: #006561;
  }

  .ant-pagination-item {
    border-radius: 3px;
  }
`;
