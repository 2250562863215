import React from "react";
import styled from "styled-components";
import { useRouterMeta } from "../Layout";
import { Breadcrumb, Button } from "antd";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
`;
const Left = styled.div``;
const LeftTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  color: #000000;
`;
const LeftBreadcrumb = styled(Breadcrumb)`
  & span {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const RejectButton = styled(Button)`
  height: 32px;
  background: #D66A3E;
  border-radius: 30px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-left: 12px;
  border-color: rgba(255, 255, 255, 0) !important;

  &:hover, &:focus {
    background: #d66a3e;
  }


  & i {
    font-size: 14px;
    margin-right: 3px;
  }

  & svg {
    margin-right: 5px;
  }
`;
const ApproveButton = styled(Button)`
  height: 32px;
  background: #527C56;
  border-radius: 30px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-left: 12px;
  border-color: rgba(255, 255, 255, 0) !important;

  & i {
    font-size: 14px;
    margin-right: 3px;
  }

  & svg {
    margin-right: 5px;
  }

  &:hover, &:focus {
    background: #527c56;
  }
`;

const AddButton = styled(Button)`
  height: 32px;
  background: #006561;
  border-radius: 30px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-left: 12px;
  border-color: rgba(255, 255, 255, 0) !important;

  & i {
    font-size: 14px;
    margin-right: 3px;
  }

  & svg {
    margin-right: 5px;
  }

  &:hover, &:active, &:focus {
    background: #006561;
    color: #ffffff !important;
    opacity: 0.8;
  }
`;

export default function Header(props: { title?: string | any, breadcrumb?: string | any, onCreate?: any; onReject?: any; onApprove?: any; contentComponent?: any }) {
  const routerMeta: any = useRouterMeta();

  function itemRenderBreadcrumb(route: any, params: any, routes: any, paths: any) {
    return !route?.path ? <span>{props.title ? props.title : route.breadcrumbName}</span> : <Link to={route.path}>{route.breadcrumbName}</Link>;
  }

  return (
    <Container>
      <Left>
        <LeftBreadcrumb itemRender={itemRenderBreadcrumb} routes={routerMeta?.breadcrumb || []} />
        <LeftTitle>{props.breadcrumb ? props.breadcrumb : routerMeta?.title}</LeftTitle>
      </Left>
      <Right>
        {props?.contentComponent ? (
          props?.contentComponent
        ) : null}
        {props?.onCreate ? (
          <AddButton onClick={props?.onCreate}>
            <i className="fa-solid fa-plus" />
            Create
          </AddButton>
        ) : null}
        {props?.onReject ? (
          <RejectButton onClick={props?.onReject}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 0C3.129 0 0 3.129 0 7C0 10.871 3.129 14 7 14C10.871 14 14 10.871 14 7C14 3.129 10.871 0 7 0ZM8.81277 4.19977L6.99977 6.01277L5.18677 4.19977L4.19977 5.18677L6.01277 6.99977L4.19977 8.81277L5.18677 9.79977L6.99977 7.98677L8.81277 9.79977L9.79977 8.81277L7.98677 6.99977L9.79977 5.18677L8.81277 4.19977ZM1.40011 7.00011C1.40011 10.0871 3.91311 12.6001 7.00011 12.6001C10.0871 12.6001 12.6001 10.0871 12.6001 7.00011C12.6001 3.91311 10.0871 1.40011 7.00011 1.40011C3.91311 1.40011 1.40011 3.91311 1.40011 7.00011Z"
                fill="white"
              />
            </svg>
            Reject
          </RejectButton>
        ) : null}
        {props?.onApprove ? (
          <ApproveButton onClick={props?.onApprove}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.50009 13.5001C4.19259 13.5001 1.50009 10.8076 1.50009 7.50009C1.50009 4.19259 4.19259 1.50009 7.50009 1.50009C10.8076 1.50009 13.5001 4.19259 13.5001 7.50009C13.5001 10.8076 10.8076 13.5001 7.50009 13.5001ZM6.00018 9.12738L10.9427 4.18488L12.0002 5.24988L6.00018 11.2499L3.00018 8.24988L4.05768 7.19238L6.00018 9.12738Z"
                fill="white"
              />
            </svg>
            Approve
          </ApproveButton>
        ) : null}
      </Right>
    </Container>
  );
}
