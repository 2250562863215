import styled from "styled-components";
import Sidebar from "./sidebar";
import { Topbar } from "./topbar";
import { useLocation, matchPath } from "react-router-dom";
import { routers } from "../../routes";
import { useState } from "react";

export const Container = styled.div`
  padding: 0px;
`;
export const ContainerView = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 24px 12px;
`;

const Main = styled.div`
  background: #EBEDF0;
`;

const AppView = styled.div`
  padding-left: 270px;

  &.active {
    padding-left: 45px;
  }
`;
const ContentView = styled.div`
  margin: 12px;
  overflow-y: auto;
`;
const SidebarView = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;

  &.close > div {
    width: 45px;
  }
`;

export const useRouterMeta = (props?: any) => {
  let location = useLocation();
  const router = routers.find(item => matchPath(item.path, location.pathname));
  return router?.meta || null
}

export const Layout = (props: {
  children?: any
}) => {
  const routerMeta = useRouterMeta();
  const [expand, setExpand] = useState(true);

  return (
    <Main style={{ minHeight: window.innerHeight }}>
      <SidebarView className={expand ? 'active' : 'close'}>
        <Sidebar expand={expand} onExpand={(value: boolean) => {
          setExpand(!expand)
        }} />
      </SidebarView>
      <AppView className={!expand ? 'active' : 'close'}>
        <Topbar title={routerMeta?.title} />

        <ContentView>
          {props.children}
        </ContentView>
      </AppView>
    </Main>
  )
}

////////////////////////
const ContentContainer = styled.div`
  display: flex;

`;
const ContentRight = styled.div`
  width: 350px;
  margin-left: 12px;
`;
const ContentRightTop = styled.div`
  border-bottom: 1px solid #DDDDDD;
  height: 40px;
  margin-top: -24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  & h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #006561;
    margin: 0;
    display: block;
  }
`;
const ContentLeft = styled.div`
  flex: 1;
`;
const ContentLeftTop = styled.div`
  & .ant-form-item-label {
    display: none;
  }
`;
export const ContentViewLayout = (props: {
  header?: any,
  body?: any,
  footer?: any,
  bodyExtend?: any,
  hiddenRightContent?: any,
}) => {
  return (
    <ContentContainer>
      <ContentLeft>
        <ContentLeftTop>{props?.header}</ContentLeftTop>
        <ContainerView>{props?.body}</ContainerView>
        {props?.bodyExtend ?
          <ContainerView style={{ marginTop: 12, marginBottom: 24 }}>{props?.bodyExtend}</ContainerView> : null}
      </ContentLeft>
      {!props?.hiddenRightContent && (
        <ContentRight>
          <ContainerView>
            <ContentRightTop>
              <h1>Publish</h1>
            </ContentRightTop>
            {props?.footer}
          </ContainerView>
        </ContentRight>
      )}
    </ContentContainer>
  )
}

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  width: 100%;
`;
export const Col: any = styled.div`
  width: 100%;
  flex: 1;
`;
export const ColSpace: any = styled.div`
  width: ${(props: any) => props.width};
`;