import React, { useEffect, useState } from "react";
import {
    TableFooterContainer,
    TableFooterPagination,
    TableFooterSize,
    TableHeadContainer, TableHeadFilter,
    TableHeadTitle
} from "./styles";
import { ActionsView, TableView } from "../Controls/table";
import { sleep } from "../../services/app.service";
import { Button, Form, Input, message, Select } from "antd";
import { SelectFormServiceView, TabEditView } from "../Form/index.";
import produce from "immer";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { CATEGORY } from "../../pages/trail/list";
import moment from "moment";

const TableContext = React.createContext<any>({});

export const TableProvider = ({ children, removeService, listService, paging, initQuery }: any) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);
    const [query, setQuery] = useState<any>({});

    useEffect(() => {
        loadData().then()
    }, [page, limit, query, initQuery]);


    const loadData = async () => {
        setLoading(true);
        let category = query?.category ? query?.category : initQuery?.category
        const result = await listService(paging ? {
            page,
            limit,
            ...query,
            ...(initQuery || {}),
            category
        } : {
            ...query,
            ...(initQuery || {}),
            category
        });
        if (result.status === 200 && result?.data?.data?.items?.length >= 0) {
            setRows(result?.data?.data?.items);
            setTotal(result.data?.data?.page_info?.total || result?.data?.data?.length || 0)
        } else {
            if (result?.data?.data?.length >= 0) {
                setRows(result?.data?.data);
                setTotal(result?.data?.data?.length || 0)
            } else {
                setRows([]);
                setTotal(0)
            }
        }
        setLoading(false);
    }

    const onExport = async () => {
        setLoading(true);
        let category = query?.category ? query?.category : initQuery?.category
        let limit = 500
        let page = 1
        const result = await listService(paging ? {
            page: page,
            limit: limit,
            ...query,
            ...(initQuery || {}),
            export_csv: true,
            category,
        } : {
            page: page,
            limit: limit,
            export_csv: true,
            ...query,
            ...(initQuery || {}),
            category,
        });
        let totalPage = Math.ceil(result?.data?.data?.page_info?.total / limit)
        if (result.status === 200 && result?.data?.data?.items?.length >= 0) {
            let itemDraft: any[] = result?.data?.data?.items ?? []
            let getItem = async (i: number) => {
                let result2 = await listService(paging ? {
                    page: i,
                    limit: limit,
                    ...query,
                    ...(initQuery || {}),
                    export_csv: true,
                    category,
                } : {
                    page: i,
                    limit: limit,
                    export_csv: true,
                    ...query,
                    ...(initQuery || {}),
                    category,
                });
                itemDraft = [...itemDraft, ...result2?.data?.data?.items]
            }
            let promiseList = []
            for (let i = 2; i <= totalPage; i++) {
                promiseList.push(getItem(i))
            }
            Promise.all(promiseList).then(async (results) => {
                let itemList = itemDraft.map((i: any) => {
                    const getName = (list: any) => {
                        if (list && list?.length > 0) {
                            return list.map(((i: any) => {
                                return i?.name ?? i?.key
                            }))
                        }
                        return ' '
                    }
                    let activityName = getName(i.activity)
                    let marathon_attribute_typ = getName(i.marathon_attribute_type)
                    let suitability = getName(i.suitability)
                    let attractions = getName(i.attractions)
                    let conditions = getName(i.conditions)
                    let trail_traffics = getName(i.trail_traffics)
                    let parkings = getName(i.parkings)
                    let service_stay = getName(i.service_stay)
                    let take_away = getName(i.take_away)
                    let service_eat_local = getName(i.service_eat_local)
                    let door = getName(i.door)
                    let route_type = i?.route_type?.name
                    return {
                        id: i.id,
                        name: i.name,
                        created_at: i.createdAt ? moment(i.createdAt).format("hh:mm A DD/MM/YYYY") : "",
                        address: i.address,
                        owner: i.owner_name ?? i.owner_info?.full_name,
                        activity: activityName.toString(),
                        marathon_attribute_type: marathon_attribute_typ.toString(),
                        suitability: suitability.toString(),
                        attractions: attractions.toString(),
                        conditions: conditions.toString(),
                        route_type: route_type,
                        vehicle: trail_traffics.toString(),
                        parkings: parkings.toString(),
                        service_stay: service_stay.toString(),
                        take_away: take_away.toString(),
                        service_eat_local: service_eat_local.toString(),
                        door: door.toString(),
                        level: i.difficulty?.name,
                        status: i.status,
                        short_description: i.short_description,
                        description: i.description,
                        link: i.link,
                        feature_image: i?.feature_image?.origin,
                        elevation_gain: i?.elevation_gain,
                        price: i?.price,
                        opening_time: `${i?.opening_time?.from} - ${i?.opening_time?.to}`,
                        room: i?.room,
                        capacity: i?.capacity,
                        perimeter: i?.perimeter,
                    }
                })
                let columns = [
                    { header: 'ID', key: 'id' },
                ];
                if (category) {
                    columns = [
                        ...columns,
                        { header: 'Images', key: 'feature_image' },
                        { header: 'Name', key: 'name' },
                    ]
                } else {
                    columns = [
                        ...columns,
                        { header: 'Name', key: 'name' },
                        { header: 'Status', key: 'status' },
                        { header: 'Owner', key: 'owner' },
                        { header: 'Description', key: 'description' },
                        { header: 'Link on App', key: 'link' },
                    ]
                }

                if (category === CATEGORY.TRAIL || category === CATEGORY.ECO_ZONES || category === CATEGORY.HERITAGE) {
                    columns = [
                        ...columns,
                        { header: 'Location', key: 'address' },
                        { header: 'Length', key: 'perimeter' },
                        { header: 'Elevation gain', key: 'elevation_gain' },
                        { header: 'Route type', key: 'route_type' },
                        { header: 'Description', key: 'short_description' },
                        { header: 'Activity', key: 'activity' },
                        { header: 'Suitability', key: 'suitability' },
                        { header: 'Attractions', key: 'attractions' },
                        { header: 'Conditions', key: 'conditions' },
                        { header: 'Vehicle', key: 'vehicle' },
                        { header: 'Link on App', key: 'link' },
                    ]
                }
                if (category === CATEGORY.MARATHON) {
                    columns = [
                        ...columns,
                        { header: 'Location', key: 'address' },
                        { header: 'Length', key: 'perimeter' },
                        { header: 'Elevation gain', key: 'elevation_gain' },
                        { header: 'Route type', key: 'route_type' },
                        { header: 'Description', key: 'short_description' },
                        { header: 'Level', key: 'level' },
                        { header: 'Marathon Type', key: 'marathon_attribute_type' },
                        { header: 'Attractions', key: 'attractions' },
                        { header: 'Conditions', key: 'conditions' },
                        { header: 'Link on App', key: 'link' },
                    ]
                }
                if (category === CATEGORY.STAY) {
                    columns = [
                        ...columns,
                        { header: 'Location', key: 'address' },
                        { header: 'Description', key: 'short_description' },
                        { header: 'Price per night', key: 'price' },
                        { header: 'Room', key: 'room' },
                        { header: 'Capacity', key: 'capacity' },
                        { header: 'Vehicle', key: 'vehicle' },
                        { header: 'Parking', key: 'parkings' },
                        { header: 'Suitability', key: 'suitability' },
                        { header: 'Service', key: 'service_stay' },
                        { header: 'Attractions', key: 'attractions' },
                        { header: 'Link on App', key: 'link' },
                    ]
                }
                if (category === CATEGORY.EAT_LOCAL) {
                    columns = [
                        ...columns,
                        { header: 'Location', key: 'address' },
                        { header: 'Description', key: 'short_description' },
                        { header: 'Price range', key: 'price' },
                        { header: 'Opening time', key: 'opening_time' },
                        { header: 'Vehicle', key: 'vehicle' },
                        { header: 'Parking', key: 'parkings' },
                        { header: 'Take away', key: 'take_away' },
                        { header: 'Service', key: 'service_eat_local' },
                        { header: 'Door', key: 'door' },
                        { header: 'Link on App', key: 'link' },
                    ]
                }

                const workbook = new Excel.Workbook();
                const workSheetName = 'Worksheet-1';
                const fileName = `Export ${category ?? 'challenges'}`;
                const worksheet = workbook.addWorksheet(workSheetName);
                worksheet.columns = columns;
                worksheet.columns.forEach((column: any) => {
                    column.width = column.header.length + 5;
                    column.alignment = { horizontal: 'center' };
                });
                itemList.forEach((singleData: any) => {
                    worksheet.addRow(singleData);
                });

                worksheet.eachRow({ includeEmpty: false }, (row: any) => {
                    const currentCell = row._cells;

                    currentCell.forEach((singleCell: any) => {
                        const cellAddress = singleCell._address;

                        // apply border
                        worksheet.getCell(cellAddress).border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' }
                        };
                    });
                });
                const buf = await workbook.xlsx.writeBuffer();
                saveAs(new Blob([buf]), `${fileName}.xlsx`);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }

    const onRemove = async (id: string) => {
        const { data, status } = await removeService(id);
        if (status === 200) {
            await sleep(2000);
            message.success("Remove success!");
            loadData().then();
        } else {
            message.error(data?.message);
        }
    }


    return (
        <TableContext.Provider
            value={{
                rows,
                setRows,
                loading,
                setLoading,
                total,
                setTotal,
                page,
                setPage,
                limit,
                setLimit,
                query,
                setQuery,
                onRemove,
                loadData,
                onExport,
            }}>
            {children}
        </TableContext.Provider>
    )
}
export const useTable = () => React.useContext(TableContext);

export const TableHead = (props: any) => {
    const hook: any = useTable();
    const filters: Array<any> = props.filters || [];
    const disableSearch: boolean = props?.disableSearch;
    const [form] = Form.useForm();
    useEffect(() => {
        if (props?.isReset) {
            form.resetFields()
        }
    }, [props]);

    return (
        <TableHeadContainer>
            <TableHeadTitle>{props.title.replace("${COUNT}", hook.total || hook?.rows?.length || 0)}</TableHeadTitle>
            <TableHeadFilter>
                <Form form={form} onFinish={values => {
                    hook.setPage(1)
                    hook.setQuery({ ...hook.query, ...values });
                }}>
                    {
                        filters?.length ? (
                            <div className={`filters ${!disableSearch ? 'search' : ''}`}>
                                {filters.map((value, index) => {
                                    return (
                                        <Form.Item key={index.toString()} hidden={value?.hidden}
                                            initialValue={value?.initialValue}
                                            name={value.name}>
                                            {value?.control || null}
                                        </Form.Item>
                                    )
                                })}
                                <Button htmlType={'submit'}>Apply</Button>
                            </div>
                        ) : null
                    }
                    {
                        !disableSearch ? (
                            <div className={'search'}>
                                <div className={'search-form'}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.84199 13.684C8.36004 13.6837 9.83434 13.1755 11.0301 12.2403L14.7898 16L15.9991 14.7907L12.2395 11.031C13.1751 9.83508 13.6836 8.36043 13.684 6.84199C13.684 3.06949 10.6145 0 6.84199 0C3.06949 0 0 3.06949 0 6.84199C0 10.6145 3.06949 13.684 6.84199 13.684ZM6.84199 1.7105C9.67201 1.7105 11.9735 4.01197 11.9735 6.84199C11.9735 9.67201 9.67201 11.9735 6.84199 11.9735C4.01197 11.9735 1.7105 9.67201 1.7105 6.84199C1.7105 4.01197 4.01197 1.7105 6.84199 1.7105Z"
                                            fill="black" fillOpacity="0.5" />
                                    </svg>
                                    <Form.Item name={'keyword'}>
                                        <Input placeholder={"Keyword"} />
                                    </Form.Item>
                                </div>

                                <Button htmlType={'submit'}>Search</Button>
                            </div>
                        ) : null
                    }
                    {
                        props?.showExport ? (
                            <div className={'search-export'}>
                                <Button onClick={() => {
                                    hook.onExport()
                                }}>Export</Button>
                            </div>
                        ) : <></>
                    }
                </Form>
            </TableHeadFilter>
        </TableHeadContainer>
    )
}

export const TableBody = (props: any) => {
    const hook: any = useTable();
    const ignoreItems: Array<any> = props.ignoreItems || [];

    return (
        <TableView {...props} dataSource={hook.rows?.filter((item: any) => !ignoreItems.find(x => x.id === item.id))}
            loading={hook.loading} pagination={false}
            onChange={(pagination, filters, sorter: any) => {
                const sortKey = sorter?.field;
                const sortBy = sorter?.order === "ascend" ? "asc" : "desc";
                hook.setQuery(produce((draft: any) => {
                    draft.sortKey = sorter?.order ? sortKey : undefined;
                    draft.sortBy = sorter?.order ? sortBy : undefined;
                }));
            }} />
    )
}

export const TableFooter = (props: any) => {
    const hook: any = useTable();

    return (
        <TableFooterContainer>
            <TableFooterSize>
                <span>Show</span>
                <Select value={hook.limit} onChange={value => {
                    hook.setPage(1);
                    hook.setLimit(value);
                }}>
                    {
                        [10, 30, 50, 100].map((value, index) => (
                            <Select.Option value={value} key={index.toString()}>{value}</Select.Option>
                        ))
                    }
                </Select>
                <span>items/page</span>
            </TableFooterSize>

            <TableFooterPagination showSizeChanger={false} pageSize={hook?.limit || 0} current={hook.page}
                onChange={(page, pageSize) => {
                    hook.setPage(page);
                }} total={hook.total} />
        </TableFooterContainer>
    )
}

export const FilterStatusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
]

export const TableHeadFilterSelect = (props: any) => {
    return (
        <Select {...props} />
    )
}

export const TableHeadFilterServiceSelect = (props: any) => {
    return (
        <SelectFormServiceView {...props} />
    )
}

export const TableActions = (props: any) => {
    const hook: any = useTable();

    return (
        <ActionsView
            style={props.style}
            onNext={props?.onNext}
            onReject={props?.onReject ? (
                async () => {
                    await props?.onReject();
                    await hook.loadData();
                }
            ) : undefined}
            onAccept={props?.onAccept ? (
                async () => {
                    await props?.onAccept();
                    await hook.loadData();
                }
            ) : undefined}
            onEdit={props?.edit?.success}
            removeTitle={props?.remove?.title}
            onRemove={props?.onRemove ? props?.onRemove : props?.remove ? (() => hook.onRemove(props.remove.id)) : null}
        />
    )
}

export const TableBodyWithValue = (props: any) => {
    return (
        <TableView
            {...props}
            pagination={false}
        />
    )
}


export const TabEditView2 = (props: any) => {
    const hook: any = useTable();
    return (
        <TabEditView
            {...props}
            onChange={(value: any) => {
                props.onChange(value)
                hook.setQuery({});
                hook.setLoading();
            }}
        />
    )
}