import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Auth} from "aws-amplify";
import Environment from "./app/environment";
import AppRouter from "./app/routes";
import {ThemeProvider} from "styled-components";
import {AppProvider} from "./app/contexts/App";
import './index.css';
// @ts-ignore
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
console.log('Version:', '1.0.1');
Auth.configure({
    Auth: {
        mandatorySignIn: true,
        userPoolId: Environment.userPoolId,
        userPoolWebClientId: Environment.userPoolClientId,
    },
});

mapboxgl.accessToken = Environment.mapboxKey;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={{}}>
        <AppProvider>
            <AppRouter/>
        </AppProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
