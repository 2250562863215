import styled from "styled-components";
import {Button, Input, Checkbox, Select, Table} from "antd";

export const ButtonView = styled(Button)`
  height: 40px;
  background: #8A786A;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 12px;
  ${(props: { outline?: boolean }) => props.outline ? `
    border: 1px solid #8A786A;
    background-color: #fff;
    color: #8A786A;
  ` : ``}
`;
export const InputView = styled(Input)`
  height: 40px;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  width: 100%;
`;
export const InputPasswordView = styled(Input.Password)`
  height: 40px;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  width: 100%;
`;
export const CheckboxView = styled(Checkbox)`
  display: flex;
  align-items: center;
  
  & .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
  }

  & .ant-checkbox-inner::after {
    left: 5px;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #006561;
    border-color: #006561;
  }

  & .ant-checkbox + span {
    font-weight: 400;
    font-size: 14px;
    color: #1C1C1C;
    display: flex;
    line-height: 20px;
    padding-top: 6px;
    height: 24px;
  }
`;
export const LinkView = styled.a``;

export const SelectView = styled(Select)`
  & .ant-select-selector {
    height: 40px !important;
    border: 1px solid #DDDDDD !important;
    border-radius: 10px !important;
    width: 100%;
  }

  & .ant-select-selection-item {
    line-height: 40px !important;
  }
`;

